<main class="container py-3">
    <div class="row">
        <div class="col">
            <h1>Checkout</h1>
        </div>
    </div>

    <div class="row" *ngIf="orderPlaced">
        <div class="col d-flex flex-column align-items-center py-5 empty-cart">
            <mat-icon class="mt-5">done_outline</mat-icon>
            <h2>Your order has been placed</h2>
            <p>Click <a routerLink="/purchase-our-sires">here</a> to continue shopping.</p>
        </div>
    </div>

    <div class="row" *ngIf="!orderPlaced && (!items || !items.length)">
        <div class="col d-flex flex-column align-items-center py-5 empty-cart">
            <mat-icon class="mt-5">shopping_cart</mat-icon>
            <h2>Shopping Cart is Empty</h2>
            <p class="mb-0">You have no items in your shopping cart.</p>
            <p>Click <a routerLink="/">here</a> to continue shopping.</p>
        </div>
    </div>

    <div class="row my-3" *ngIf="!orderPlaced && items && items.length">
        <div class="col-12 col-md-7 order-2 order-md-1 mb-3">
            <mat-card>
                <mat-card-content>
                    <h3 class="my-2">Customer Contact Information</h3>
                    <form [formGroup]="customerForm" class="d-flex flex-column">
                        <mat-form-field color="accent">
                            <mat-label>Full Name</mat-label>
                            <input matInput [formControl]="customerForm.controls.name" required>
                            <mat-error *ngIf="customerForm.controls.name.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Farm Name</mat-label>
                            <input matInput [formControl]="customerForm.controls.farmName">
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Street Address</mat-label>
                            <input matInput [formControl]="customerForm.controls.streetAddress" required>
                            <mat-error *ngIf="customerForm.controls.streetAddress.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>City</mat-label>
                            <input matInput [formControl]="customerForm.controls.city" required>
                            <mat-error *ngIf="customerForm.controls.city.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>State</mat-label>
                            <mat-select [formControl]="customerForm.controls.state" required>
                                <mat-option value="Alabama">Alabama</mat-option>
                                <mat-option value="Alaska">Alaska</mat-option>
                                <mat-option value="Arizona">Arizona</mat-option>
                                <mat-option value="Arkansas">Arkansas</mat-option>
                                <mat-option value="California">California</mat-option>
                                <mat-option value="Colorado">Colorado</mat-option>
                                <mat-option value="Connecticut">Connecticut</mat-option>
                                <mat-option value="Delaware">Delaware</mat-option>
                                <mat-option value="District Of Columbia">District Of Columbia</mat-option>
                                <mat-option value="Florida">Florida</mat-option>
                                <mat-option value="Georgia">Georgia</mat-option>
                                <mat-option value="Hawaii">Hawaii</mat-option>
                                <mat-option value="Idaho">Idaho</mat-option>
                                <mat-option value="Illinois">Illinois</mat-option>
                                <mat-option value="Indiana">Indiana</mat-option>
                                <mat-option value="Iowa">Iowa</mat-option>
                                <mat-option value="Kansas">Kansas</mat-option>
                                <mat-option value="Kentucky">Kentucky</mat-option>
                                <mat-option value="Louisiana">Louisiana</mat-option>
                                <mat-option value="Maine">Maine</mat-option>
                                <mat-option value="Maryland">Maryland</mat-option>
                                <mat-option value="Massachusetts">Massachusetts</mat-option>
                                <mat-option value="Michigan">Michigan</mat-option>
                                <mat-option value="Minnesota">Minnesota</mat-option>
                                <mat-option value="Mississippi">Mississippi</mat-option>
                                <mat-option value="Missouri">Missouri</mat-option>
                                <mat-option value="Montana">Montana</mat-option>
                                <mat-option value="Nebraska">Nebraska</mat-option>
                                <mat-option value="Nevada">Nevada</mat-option>
                                <mat-option value="New Hampshire">New Hampshire</mat-option>
                                <mat-option value="New Jersey">New Jersey</mat-option>
                                <mat-option value="New Mexico">New Mexico</mat-option>
                                <mat-option value="New York">New York</mat-option>
                                <mat-option value="North Carolina">North Carolina</mat-option>
                                <mat-option value="North Dakota">North Dakota</mat-option>
                                <mat-option value="Ohio">Ohio</mat-option>
                                <mat-option value="Oklahoma">Oklahoma</mat-option>
                                <mat-option value="Oregon">Oregon</mat-option>
                                <mat-option value="Pennsylvania">Pennsylvania</mat-option>
                                <mat-option value="Rhode Island">Rhode Island</mat-option>
                                <mat-option value="South Carolina">South Carolina</mat-option>
                                <mat-option value="South Dakota">South Dakota</mat-option>
                                <mat-option value="Tennessee">Tennessee</mat-option>
                                <mat-option value="Texas">Texas</mat-option>
                                <mat-option value="Utah">Utah</mat-option>
                                <mat-option value="Vermont">Vermont</mat-option>
                                <mat-option value="Virginia">Virginia</mat-option>
                                <mat-option value="Washington">Washington</mat-option>
                                <mat-option value="West Virginia">West Virginia</mat-option>
                                <mat-option value="Wisconsin">Wisconsin</mat-option>
                                <mat-option value="Wyoming">Wyoming</mat-option>
                            </mat-select>
                            <mat-error *ngIf="customerForm.controls.state.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Country</mat-label>
                            <input matInput [formControl]="customerForm.controls.country" required>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Zip Code</mat-label>
                            <input matInput [formControl]="customerForm.controls.zipCode" required>
                            <mat-error *ngIf="customerForm.controls.zipCode.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>E-mail</mat-label>
                            <input matInput [formControl]="customerForm.controls.email" required>
                            <mat-error *ngIf="customerForm.controls.email.errors?.required">
                                required field
                            </mat-error>
                            <mat-error *ngIf="customerForm.controls.email.errors?.email">
                                invalid e-mail
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Phone</mat-label>
                            <input matInput [formControl]="customerForm.controls.phone" required>
                            <mat-error *ngIf="customerForm.controls.phone.errors?.required">
                                required field
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field color="accent">
                            <mat-label>Special shipping instruction or comments</mat-label>
                            <textarea matInput rows="2" [formControl]="customerForm.controls.comments"></textarea>
                        </mat-form-field>
                        <div class="recaptcha-container">
                            <span class="mr-2"
                                [class.error]="customerForm.controls.recaptcha.touched && customerForm.controls.recaptcha.errors?.required">
                                *
                            </span>
                            <re-captcha formControlName="recaptcha"></re-captcha>
                        </div>
                        <p class="error my-3" *ngIf="!cartIsValid">*Products are available for purchase in increments of 10</p>
                        <button type="button" mat-raised-button
                            class="green-button py-2 my-2 text-uppercase align-self-center" (click)="placeOrder()">
                            Place Order
                        </button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12 col-md-5 order-1 order-md-2 mb-3">
            <mat-card class="cart-summary">
                <mat-card-content>
                    <h3 class="my-2">Order</h3>
                    <table class="w-100">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th class="text-right">Qty</th>
                                <th class="text-right unit-price">Unit Price</th>
                                <th class="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items">
                                <td>
                                    <span class="d-block">{{item.fullName}}</span>
                                    <span>{{item.semenCode}} - {{item.intRegNum}}</span>
                                </td>
                                <td class="text-right"><span>{{item.quantity}}</span></td>
                                <td class="text-right"><span>{{item.price | currency}}</span></td>
                                <td class="text-right"><span>{{(item.quantity * item.price) | currency}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex mb-1" *ngIf="scService.getDiscount()">
                        <span class="flex-grow-1">Discount ({{scService.getDiscountPercent() + '%'}})</span>
                        <span>({{scService.getDiscount() | currency}})</span>
                    </div>
                    <div class="d-flex mb-1">
                        <span class="flex-grow-1">Shipping Cost</span>
                        <span>{{scService.getShippingTax() | currency}}</span>
                    </div>
                    <div class="d-flex mb-1">
                        <h3 class="flex-grow-1">Grand Total</h3>
                        <h3>{{scService.getGrandTotal() | currency}}</h3>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <app-shopping-cart-button *ngIf="!orderPlaced && items && items.length"></app-shopping-cart-button>
</main>