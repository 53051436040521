import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { BullSearchService } from '../services/bull-search.service'
import { BullListItem } from '../models/bull-list-item.model';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from '../services/loader.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { catalogItemsKey, defaultColumnsDefinitions, pageTitles } from '../utils/constants';
import { MatDialog } from '@angular/material/dialog';
import { TraitsDialogComponent } from '../traits-dialog/traits-dialog.component';
import { Title } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert.component';
import { CatalogComponent } from '../catalog/catalog.component';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-our-sires',
  templateUrl: './purchase-our-sires.component.html',
  styleUrls: ['./purchase-our-sires.component.scss']
})
export class PurchaseOurSiresComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('tableContainer', { read: ElementRef }) tableContainer: ElementRef<any>;
  bulls: BullListItem[] = [];
  dataSource: MatTableDataSource<BullListItem>;
  selection = new SelectionModel<BullListItem>(true, []);

  columnsDefinitions = defaultColumnsDefinitions;
  displayedColumns: string[];

  matSideNavContent: HTMLElement;
  pageSizeOptions = [25, 50, 100];

  filter = '';
  filterUpdate = new Subject<string>();

  sizeChanged = new Subject(); 

  constructor(private _bsService: BullSearchService, public loaderService: LoaderService, public dialog: MatDialog,
    private _titleService: Title, public _datepipe: DatePipe, private _router: Router) {
    this.displayedColumns = this.columnsDefinitions.filter((cd) => cd.show).map((cd) => cd.definition);
  }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.purchaseOurSires);
    this.filterUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });

    this.sizeChanged.pipe(
      debounceTime(200))
      .subscribe(() => {
        this.toggleScrollButtonsVisibility();
      });
  }

  ngAfterViewInit(): void {
    this.matSideNavContent = window.document.getElementById('matSidenavContent');
    this.loadBulls();
  }


  loadBulls() {
    this._bsService.getBulls().subscribe((response) => {
      this.bulls = response;
      this.dataSource = new MatTableDataSource(response);

      if (this.dataSource.data.length > 100) {
        this.pageSizeOptions.push(this.dataSource.data.length)
      }

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSizeOptions = this.pageSizeOptions;
        this.dataSource.filterPredicate = (data: BullListItem, filter: string) => !filter
          || data.SemenCode.toLowerCase().includes(filter.toLowerCase())
          || data.ShortName.toLowerCase().includes(filter.toLowerCase())
          || data.FullName.toLowerCase().includes(filter.toLowerCase());
        this.dataSource.sort = this.sort;

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.paginator.page.subscribe((event) => {
          setTimeout(() => {
            this.matSideNavContent.scrollTop = 0;
          }, 100);
        });

        this.updateRightScrollButtonPosition();
        this.toggleScrollButtonsVisibility();
      }, 200);
    }, error => {
      console.log(error);
    });
  }

  scroll(value: number) {
    this.tableContainer.nativeElement.scrollLeft += value;
  }

  clearFilter() {
    this.filter = '';
    this.filterUpdate.next(this.filter);
  }

  openTraitsDialog(): void {
    const dialogRef = this.dialog.open(TraitsDialogComponent, {
      data: { columnsDefinitions: this.columnsDefinitions },
      autoFocus: false,
      width: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.columnsDefinitions = result;
        this.displayedColumns = this.columnsDefinitions.filter((cd) => cd.show).map((cd) => cd.definition);
        setTimeout(() => {
          this.toggleScrollButtonsVisibility();
        }, 200);        
      }
    });
  }

  openCreateCatalog(): void {
    if (this.selection.selected.length > 0)
    {
      localStorage.setItem(catalogItemsKey, JSON.stringify(this.selection.selected.map(item => item.permalink)));
      this._router.navigateByUrl('/catalog');
    }
    else{
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Error', message: 'Select at least one bull.'},
        autoFocus: false,
        width: '400px'
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.sizeChanged.next();
    this.updateRightScrollButtonPosition();    
  }

  updateRightScrollButtonPosition() {
    const xPosition = this.tableContainer.nativeElement.getBoundingClientRect().right;
    const rightButton = (document.querySelector('.scroll-button.right') as HTMLElement);
    rightButton.style.left = `${xPosition}px`;
  }

  toggleScrollButtonsVisibility() {
    const containerWidth = this.tableContainer.nativeElement.clientWidth;
    const tableWidth = (document.querySelector('.mat-table') as HTMLElement).clientWidth;
    const buttons = document.querySelectorAll('.scroll-button');

    if (tableWidth > containerWidth) {
      buttons.forEach((button) => {
        button.classList.remove('hide');
      })
    } else {
      buttons.forEach((button) => {
        if (!button.classList.contains('hide')) {
          button.classList.add('hide');
        }
      })
    }
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s));
  }

}
