import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.aboutUs);
  }

}
