<div class="container py-3 rules-page">
    <div class="row">
        <div class="col">
            <h1>Terms and Conditions Jetstream Genetics Inc.</h1>

            <div class="block">
                <h2 class="order">1</h2>
                <h2>Interpretation</h2>
            </div>

            <div class="block">
                <span class="order">1.1</span>
                <div>
                    <p class="mb-1"><strong>Definitions.</strong> In these Terms and Conditions, the following definitions apply:</p>
                    <p class="text-block">
                        <em>Business Day:</em> a day (other than a Saturday, Sunday or public holiday) on which businesses, banks, and stock exchanges are open.
                        <br/>
                        <em>Conditions:</em> these Terms and Conditions as amended from time to time in accordance with Section 20.5.
                        <br/>
                        <em>Contract:</em> the written or oral agreement between the Supplier and the Customer for the supply of Goods and/or Services in accordance with these Terms and Conditions.
                        <br/>
                        <em>Customer:</em> the person, firm or any other third party who purchases the Goods and/or Services from the Supplier.
                        <br/>
                        <em>Data:</em> has the meaning given in Section 13.5.
                        <br/>
                        <em>Data Protection Legislation:</em> any applicable law relating to processing, use, and privacy of Personal Data.
                        <br/>
                        <em>Delivery Location:</em> has the meaning set out in Section 3.
                        <br/>
                        <em>Force Majeure Event:</em> has the meaning given to it in Section 17.
                        <br/>
                        <em>Goods:</em> the goods (or any part of them, including any related services) set out in the Order. Approved for animal agricultural use only.
                        <br/>
                        <em>Intellectual Property Rights:</em> all patents, rights to inventions, utility models, copyright and related rights, trademarks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database right, topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.
                        <br/>
                        <em>Order:</em> the Customer’s oral or written order for the supply of Goods and/or Services, as set out in the Customer’s acceptance on receipt of the written confirmation by Supplier to sell or supply to the Customer Goods and/or Services described therein (Order Confirmation); or the order as described in the invoice or similar document as agreed by both parties for Goods and/or Services, as the case may be. Each Order incorporates and is subject to these Terms and Conditions.
                        <br/>
                        <em>Order Form:</em> the form or Order Confirmation detailing key terms of the Contract.
                        <br/>
                        <em>Parties:</em> the supplier and the Customer.
                        <br/>
                        <em>Personal Data:</em> has the meaning given in Section 13.1.
                        <br/>
                        <em>Price:</em> the price payable by the Customer for the Goods and/or Services as set out in the Order.
                        <br/>
                        <em>Requested Delivery Date:</em> means the date specified in the Order as the date requested by the Customer for delivery of the Goods and/or performance of the Services.
                        <br/>
                        <em>Service:</em> the services supplied by the Supplier to the Customer as set out in the Order.
                        <br/>
                        <em>Single Use A.I.:</em> means that a straw of semen may only be used once to artificially inseminate a single naturally ovulating or estrus synchronized female of the same species as the semen, as long as the following conditions are met:) the female has not been super-ovulated, and the resulting embryo is not flushed or transferred into a recipient, but remains in the female where the embryo is fertilized until no longer viable, or resulting in the birth of a calf.
                        <br/>
                        <em>Supplier Materials:</em> has the meaning set out in Section 6.1.6.
                        <br/>
                    </p>
                </div>
            </div>
            
            <div class="block">
                <span class="order">1.2</span>
                <p>a person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality).</p> 
            </div>

            <div class="block">
                <span class="order">1.3</span>
                <p>a reference to a party includes its successors or permitted assignments.</p> 
            </div>

            <div class="block">
                <span class="order">1.3</span>
                <p>a reference to a party includes its successors or permitted assignments.</p> 
            </div>

            <div class="block">
                <span class="order">1.4</span>
                <p>a reference to a statute or statutory provision is a reference to such statute or statutory provision as amended or re-enacted. A reference to a statute or statutory provision includes any subordinate legislation made under that statute or statutory provision, as amended or re-enacted; and</p> 
            </div>

            <div class="block">
                <span class="order">1.5</span>
                <p>any phrase introduced by the terms including, include, in particular or any similar expression shall be construed as illustrative and shall not limit the sense of the words preceding those terms.</p> 
            </div>

            <div class="block">
                <h2 class="order">2</h2>
                <h2>Basis of Contract</h2>
            </div>

            <div class="block">
                <span class="order">2.1</span>
                <p>Any Contract between the Supplier and the Customer for the sale by the Supplier to the Customer of any Goods and/or Services, and the purchase of such Goods and/or Services by the Customer from the Supplier, shall be subject to these Terms and Conditions, unless the Supplier expressly agrees otherwise in writing. All other terms and conditions are excluded, including any terms and conditions which the Customer seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</p> 
            </div>

            <div class="block">
                <span class="order">2.2</span>
                <p>The Order constitutes an offer by the Customer to purchase Goods and/or Services in accordance with these Terms and Conditions. The Customer shall ensure that the terms of the Order are complete and accurate.</p> 
            </div>

            <div class="block">
                <span class="order">2.3</span>
                <p>The Order shall only be deemed to be accepted on the earlier of:</p> 
            </div>

            <div class="block">
                <span class="order">2.3.1</span>
                <p>the Supplier signing the Order Form; or</p> 
            </div>

            <div class="block">
                <span class="order">2.3.2</span>
                <p>the Supplier commencing delivery of the Goods and/or performance of the Services, at which point the Contract shall come into existence (Commencement Date).</p> 
            </div>

            <div class="block">
                <span class="order">2.4</span>
                <p>The Contract constitutes the entire agreement between the parties. The Customer acknowledges that it has not relied on any statement, promise or representation made or given by or on behalf of the Supplier which is not set out in the Contract. Any descriptive matter or advertising issued by the Supplier and any descriptions of the Goods and/or Services contained in the Supplier’s catalogues or brochures are issued or published for the sole purpose of giving an approximate idea of the Goods and/or Services described in them. They shall not form part of the Contract or any other contract between the Supplier and the Customer for the supply of the Goods and/or Services.</p> 
            </div>

            <div class="block">
                <span class="order">2.5</span>
                <p>Any quotation given by the Supplier shall not constitute an offer and is only valid for a period of 10 (ten) Business Days from its date of issue.</p> 
            </div>

            <div class="block">
                <span class="order">2.6</span>
                <p>All of these Terms and Conditions shall apply to the supply of both Goods and Services except where application to one or the other is specified.</p> 
            </div>

            <div class="block">
                <h2 class="order">3</h2>
                <h2>Delivery of Goods</h2>
            </div>

            <div class="block">
                <span class="order">3.1</span>
                <p>The Supplier will deliver the Goods to the location set out in the Order or such other location as the parties may agree (“Delivery Location”) at any time after the Supplier notifies the Customer that the Goods are ready.</p> 
            </div>

            <div class="block">
                <span class="order">3.2</span>
                <p>Delivery of the Goods shall be completed on the Goods’ arrival at the Delivery Location.</p> 
            </div>

            <div class="block">
                <span class="order">3.3</span>
                <p>The Supplier shall use its reasonable endeavors to make the Goods available for delivery or collection on the Requested Delivery Date, but the Supplier shall not incur any liability whatever in the event of any delay however caused. For the avoidance of doubt, time for delivery of the Goods shall not be of the essence.</p> 
            </div>

            <div class="block">
                <span class="order">3.4</span>
                <p>The Supplier shall ensure that each delivery of the Goods is accompanied by a delivery note which shows the date of the Order, all relevant reference numbers of Customer and Supplier, the type and quantity of the Goods (including the code number of the Goods, where applicable) and any special storage instructions.</p> 
            </div>

            <div class="block">
                <span class="order">3.5</span>
                <p>For the avoidance of doubt, the Customer shall not be entitled to reject the Goods if the Supplier delivers up to and including 10% (ten) less than the quantity of Goods ordered.</p> 
            </div>

            <div class="block">
                <span class="order">3.6</span>
                <p>The Supplier may deliver the Goods by installments, which shall be invoiced and paid for separately. Each installment shall constitute a separate Contract. Any delay in delivery or defect in an instalment shall not entitle the Customer to cancel any other instalment.</p> 
            </div>

            <div class="block">
                <h2 class="order">4</h2>
                <h2>Title and Risk</h2>
            </div>

            <div class="block">
                <span class="order">4.1</span>
                <p>The risk in the Goods shall pass to the Customer on completion of delivery of the whole or part of the Goods (as applicable).</p> 
            </div>

            <div class="block">
                <span class="order">4.2</span>
                <p>Title to the Goods shall not pass to the Customer until the Supplier has received payment in full (in cash or cleared funds) for</p> 
            </div>

            <div class="block">
                <span class="order">4.2.1</span>
                <p>the Goods and/or Services; and</p> 
            </div>

            <div class="block">
                <span class="order">4.2.2</span>
                <p>any other Goods or Services that the Supplier has supplied to the Customer in respect of which payment has become due.</p> 
            </div>

            <div class="block">
                <span class="order">4.3</span>
                <p>If before title to the Goods passes to the Customer, the Customer becomes subject to any of the events listed in Section 15.2, or the Supplier reasonably believes that any such event is about to happen and notifies the Customer accordingly, then, provided the Goods have not been resold, and without limiting any other right or remedy the Supplier may have, the Supplier may at any time require the Customer to deliver up the Goods and, if the Customer fails to do so promptly, enter any premises of the Customer or of any third party where the Goods are stored in order to recover them.</p> 
            </div>

            <div class="block">
                <h2 class="order">5</h2>
                <h2>Supply of Services</h2>
            </div>

            <div class="block">
                <span class="order">5.1</span>
                <p>The Supplier shall:</p> 
            </div>

            <div class="block">
                <span class="order">5.1.1</span>
                <p>perform the Services at the Customer’s address specified in the Order unless otherwise agreed between the Parties;</p> 
            </div>

            <div class="block">
                <span class="order">5.1.2</span>
                <p>use reasonable endeavors to meet any performance dates for the Services specified in the Order, but any such dates shall be estimates only and time shall not be of the essence for the performance of the Services;</p> 
            </div>

            <div class="block">
                <span class="order">5.1.3</span>
                <p>have the right to make any changes to the Services which are necessary to comply with any applicable law or safety requirement, or which do not materially affect the nature or quality of the Services, and the Supplier shall notify the Customer in any such event.</p> 
            </div>

            <div class="block">
                <h2 class="order">6</h2>
                <h2>Customer’s Obligations</h2> 
            </div>

            <div class="block">
                <span class="order">6.1</span>
                <p>The Customer shall:</p> 
            </div>

            <div class="block">
                <span class="order">6.1.1</span>
                <p>co-operate with the Supplier in all matters relating to the Goods and/or Services and provide the Supplier, its employees, agents, consultants and subcontractors, with access to the Customer’s premises, office accommodation and other facilities as reasonably required by the Supplier to deliver the Goods and/or provide the Services;</p> 
            </div>

            <div class="block">
                <span class="order">6.1.2</span>
                <p>comply with the Supplier’s written instructions in relation to the Goods and/or Services;</p> 
            </div>

            <div class="block">
                <span class="order">6.1.3</span>
                <p>provide the Supplier with such information and materials as the Supplier may reasonably require to supply the Services, and ensure that such information is accurate in all material respects;</p> 
            </div>

            <div class="block">
                <span class="order">6.1.4</span>
                <p>prepare the Customer’s premises for the delivery of the Goods and/or supply of the Services;</p> 
            </div>

            <div class="block">
                <span class="order">6.1.5</span>
                <p>where applicable, obtain and maintain all necessary licenses, permissions and consents which may be required for the Services before the date on which the Services will start;</p> 
            </div>

            <div class="block">
                <span class="order">6.1.6</span>
                <p>keep and maintain all materials, equipment, documents and other property of the Supplier (“Supplier Materials”) at the Customer’s premises in safe custody at its own risk, maintain the Supplier Materials in good condition until returned to the Supplier, and not dispose of or use the Supplier Materials other than in accordance with the Supplier’s written instructions or authorization; and</p> 
            </div>

            <div class="block">
                <span class="order">6.2</span>
                <p>If the Supplier’s performance of any of its obligations in respect of the Goods and/or Services is prevented or delayed by any act or omission by the Customer or failure by the Customer to perform any relevant obligation (Customer Default):</p> 
            </div>

            <div class="block">
                <span class="order">6.2.1</span>
                <p>the Supplier shall without limiting its other rights or remedies have the right to suspend delivery of the Goods and/or performance of the Services until the Customer remedies the Customer Default, and to rely on the Customer Default to relieve it from the performance of any of its obligations to the extent the Customer Default prevents or delays the Supplier’s performance of any of its obligations;</p> 
            </div>

            <div class="block">
                <span class="order">6.2.2</span>
                <p>the Supplier shall not be liable for any costs or losses sustained or incurred by the Customer arising directly or indirectly from the Supplier’s failure or delay to perform any of its obligations as set out in this Section 6.2; and</p> 
            </div>

            <div class="block">
                <span class="order">6.2.3</span>
                <p>the Customer shall reimburse the Supplier on written demand for any costs (including, without limitation attorney fees) or losses sustained or incurred by the Supplier arising directly or indirectly from the Customer Default.</p> 
            </div>

            <div class="block">
                <span class="order">6.3</span>
                <p>Without prior express written consent of the Supplier, the Customer shall at all times refrain from doing anything that might influence the quality, and safety of the Goods and/or Services, reputation of the Supplier, and/or any of the trademarks or other intellectual property of the Supplier. Without prejudice to the generality of the foregoing, the Customer shall not reverse engineer, genotype, sequence, clone, reverse sort, genome edit, analyze or manipulate the Goods; or use the Goods or its derivates for any research or development use, including in public or private research programs.</p> 
            </div>

            <div class="block">
                <span class="order">6.4</span>
                <p>In the case of sorted semen, the Customer shall only use each straw of sorted semen purchased or otherwise acquired for the single artificial insemination of one female bovine with intent to produce a single offspring (“Single A.I. Use”).</p> 
            </div>

            <div class="block">
                <span class="order">6.4.1</span>
                <p>Inguran d/b/a Sexing Technologies of Navasota, Texas shall be a third-party beneficiary having a right to enforce section 6.4 to restrict the Customer’s use of sorted semen.</p> 
            </div>

            <div class="block">
                <h2 class="order">7</h2>
                <h2>Warranty and Fitness for Purpose</h2>
            </div>

            <div class="block">
                <span class="order">7.1</span>
                <p>The Customer acknowledges and accepts that:</p> 
            </div>

            <div class="block">
                <span class="order">7.1.1</span>
                <p>the Goods and/or Services shall be supplied or manufactured in accordance with the specifications for such Goods and/or Services as may have been agreed by the Supplier and the Customer in writing and shall be of good quality, and fit for purpose for which they are reasonably expected.</p> 
            </div>

            <div class="block">
                <span class="order">7.1.2</span>
                <p>progeny created using the Goods and/or Services will have varying fertility rates, characteristics and traits due to a number of factors including the fertility, genotype and characteristics of the recipient cow or heifer and the environment in which the recipient cow or heifer is kept; and</p> 
            </div>

            <div class="block">
                <span class="order">7.1.3</span>
                <p>factors outside of the Supplier’s control, including the genotype of the recipient cow or heifer and its environment may cause progeny created from the use of the Goods and/or Services to possess defects which may lead to genetic abnormalities or contribute to illness, disease, impairment, ill-health or otherwise.</p> 
            </div>

            <div class="block">
                <span class="order">7.2</span>
                <p>The Supplier shall take all reasonable steps to maintain the quality of the Goods and/or Services but cannot guarantee that the Goods and/or Services are free from any defect at the time of delivery.</p> 
            </div>

            <div class="block">
                <span class="order">7.3</span>
                <p>The Supplier shall take all reasonable steps to maintain the fertility of the Goods but cannot guarantee that the Goods are fertile at the time of delivery.</p> 
            </div>

            <div class="block">
                <span class="order">7.4</span>
                <p>The Supplier gives no guarantee that progeny will result from use of the Goods and/or Services.</p> 
            </div>

            <div class="block">
                <span class="order">7.5</span>
                <p>The Supplier gives no guarantee in respect of the sex ratio of progeny resulting from the use of the Goods and/or Services.</p> 
            </div>

            <div class="block">
                <span class="order">7.[3/6]</span>
                <p>Except as set out in these Terms and Conditions, all warranties, conditions and other terms implied by statue or (common) law are, to the fullest extent permitted by law, excluded from the Contract.</p> 
            </div>

            <div class="block">
                <h2 class="order">8</h2>
                <h2>Limitations of Liability</h2>
            </div>

            <div class="block">
                <span class="order">8.1</span>
                <p>Nothing in these Terms and Conditions shall limit or exclude the Supplier’s liability for:</p> 
            </div>

            <div class="block">
                <span class="order">8.1.1</span>
                <p>death or personal injury to any human being caused by its negligence, or the negligence of its employees, agents or subcontractors (as applicable);</p> 
            </div>

            <div class="block">
                <span class="order">8.1.2</span>
                <p>fraud or fraudulent misrepresentation; or</p> 
            </div>

            <div class="block">
                <span class="order">8.1.3</span>
                <p>any other liability which cannot at law be excluded.</p> 
            </div>

            <div class="block">
                <span class="order">8.2</span>
                <p>Subject to Section 8.1:</p> 
            </div>

            <div class="block">
                <span class="order">8.2.1</span>
                <p>the Supplier shall not be liable to the Customer, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for loss of profit; loss of business; loss of opportunity; loss of revenue; loss of, or damage to goodwill or reputation; or any other indirect or consequential loss whatsoever, arising under or in connection with the Contract or in the provision of Goods and/or Services to the Customer;</p> 
            </div>

            <div class="block">
                <span class="order">8.2.2</span>
                <p>the Customer shall be solely responsible for and hold the Supplier fully indemnified from and against any and all claims, demands, liabilities, losses, damages, proceedings, costs (including, without limitation, attorney fees) and expenses incurred by the Supplier as a result of:
                    <br/>-a breach by the Customer of any term of these Terms and Conditions; and/or
                    <br/>-the use by the Customer of the Goods otherwise than in accordance with the written instructions of the Supplier; and
                </p> 
            </div>

            <div class="block">
                <span class="order">8.2.3</span>
                <p>the Supplier’s total liability with respect to the Goods and/or Services provided to the Customer in respect of all other losses arising under or in connection with the Contract, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall be limited to the purchase price (excluding taxes) paid by Customer to Supplier for the Goods and/or Services related to the Customer’s claim for damages against Supplier.</p> 
            </div>

            <div class="block">
                <span class="order">8.3</span>
                <p>This Section 8 shall survive termination of the Contract.</p> 
            </div>
            
            <div class="block">
                <h2 class="order">9</h2>
                <h2>Price and Payment</h2>
            </div>

            <div class="block">
                <span class="order">9.1</span>
                <p>In consideration of the Goods and/or Services, the Customer shall pay to the Supplier the Price in readily available funds.</p> 
            </div>

            <div class="block">
                <span class="order">9.2</span>
                <p>Subject to Section 9.[3], the Supplier shall invoice the Customer for the Goods and/or Services in the currency stated in the invoice and in the manner stipulated in the invoice. Where the day nominated or determined for payment is not a Business Day in the country in which the Customer is located, then the day for payment shall be the first Business Day after the day nominated or determined.</p> 
            </div>

            <div class="block">
                <span class="order">9.[3]</span>
                <p>The Customer shall pay all invoices in full and in readily available funds within 30 days of the date of the invoice. Time of payment is of the essence.</p> 
            </div>

            <div class="block">
                <span class="order">9.[4]</span>
                <p>All amounts payable by the Customer under the Contract are exclusive of amounts in respect of the applicable taxes as required by applicable laws (e.g., state sales tax), which shall be charged at the prevailing rate. The Customer shall, on receipt of a valid tax invoice from the Supplier, pay to the Supplier such additional amounts in respect of the taxes as are chargeable on the supply of the Goods and/or Services.</p> 
            </div>

            <div class="block">
                <span class="order">9.[5]</span>
                <p>All payments, if not made by [payment method(s)], shall be made by cheque to the address of the Supplier stated above or at such other address as the Supplier may from time to time specify or by debit or credit card. If payment is made by credit card, then the Supplier shall have the option to invoice the applicable surcharge.</p> 
            </div>

            <div class="block">
                <span class="order">9.[6]</span>
                <p>If the Customer fails to make any payment due to the Supplier under the Contract by the due date for payment (Due Date), then the Customer shall pay interest on the overdue amount at the rate of 1.5% per month. Such interest shall accrue on daily basis from the Due Date until the date of actual payment of the overdue amount, whether before or after judgment. The Supplier shall have the right to suspend further deliveries of the Goods/and or Services without liability, until the date of actual payment of the overdue amount.</p> 
            </div>

            <div class="block">
                <span class="order">9.[7]</span>
                <p>The Customer shall pay all amounts due under the Contract in full without any deduction or withholding except as required by law and the Customer shall not be entitled to assert any credit, set-off or counterclaim against the Supplier in order to justify withholding payment of any such amount in whole or in part. The Supplier may at any time, without limiting any other rights or remedies it may have, set off any amount owing to it by the Customer against any amount payable by the Supplier to the Customer.</p> 
            </div>

            <div class="block">
                <h2 class="order">10</h2>
                <h2>Revision of the Price</h2>
            </div>

            <div class="block">
                <span class="order">10.1</span>
                <p>The Supplier may, in its absolute discretion, by giving notice to the Customer at any time before the Requested Delivery Date revise the Price or reflect any change that is due to:</p> 
            </div>

            <div class="block">
                <span class="order">10.1.1</span>
                <p>any factor beyond the Supplier’s control (including foreign exchange fluctuations, changes in taxes and duties, labor, materials and other manufacturing costs);</p> 
            </div>

            <div class="block">
                <span class="order">10.1.2</span>
                <p>any request by the Customer to change the Requested Delivery Date(s), quantities of the Goods, nature and/or scope of the Services and/or any other aspect of the Order; or</p> 
            </div>

            <div class="block">
                <span class="order">10.1.3</span>
                <p>any delay caused by any instructions of the Customer or failure of the Customer to give the Supplier adequate or accurate information or instructions.</p> 
            </div>

            <div class="block">
                <span class="order">10.2</span>
                <p>Any such revision of the Price pursuant to Section 10.1 will be notified by the Supplier to the Customer as soon as is practicable and in any event on or before the Requested Delivery Date.</p> 
            </div>

            <div class="block">
                <span class="order">10.3</span>
                <p>The Customer shall, for the period of 7 (seven) days after receipt of a notification pursuant to Section 10.1, have the right to serve notice on the Supplier cancelling the Order and, subject to Section 10.[4], the Customer will incur no liability or obligation to the Supplier on account of such cancellation.</p> 
            </div>

            <div class="block">
                <span class="order">10.4</span>
                <p>Where any increase in the Price is caused by an act or omission of the Customer, the Customer shall be liable to pay any expense or charges incurred by the Supplier up to the point of cancellation.</p> 
            </div>

            <div class="block">
                <h2 class="order">11</h2>
                <h2>Intellectual Property Rights</h2>
            </div>

            <div class="block">
                <span class="order">11.1</span>
                <p>All Intellectual Property Rights in or arising out of or in connection with the Goods and/or Services shall be owned by the Supplier.</p> 
            </div>

            <div class="block">
                <span class="order">11.2</span>
                <p>The Customer acknowledges that, in respect of any third party Intellectual Property Rights in the Goods and/or Services, the Customer’s use of any such Intellectual Property Rights is conditional on the Supplier obtaining a written license from the relevant licensor on such terms as will entitle the Supplier to license such rights to the Customer.</p> 
            </div>

            <div class="block">
                <span class="order">11.3</span>
                <p>All Supplier Materials are the exclusive property of the Supplier.</p> 
            </div>

            <div class="block">
                <h2 class="order">12</h2>
                <div>
                    <h2>Confidentiality</h2>
                    <p>A party (Receiving Party) shall keep in strict confidence all technical or commercial know-how, specifications, inventions, processes or initiatives which are of confidential nature and have been disclosed to the Receiving Party by the other party (Disclosing Party), its employees, agents or subcontractors, and any other confidential information concerning the Disclosing Party’s business or its products or its services which the Receiving Party may obtain. The Receiving Party shall restrict disclosure of such confidential information to such of its employees, agents or subcontractors as need to know it for the purpose of discharging the Receiving Party’s obligations under the Contract, and shall ensure that such employees, agents or subcontractors are subject to obligations of confidentiality corresponding to those which bind the Receiving Party. This Section 12 shall survive termination of the Contract.</p>
                </div>
                
            </div>

            <div class="block">
                <h2 class="order">13</h2>
                <h2>Data</h2>
            </div>

            <div class="block">
                <span class="order">13.1</span>
                <p class="mb-1">By placing an Order for Goods and/or Services the Customer consents to the Supplier processing personal data collected from the Customer (including name, address, telephone number and email address (“Personal Data”) for the purposes of:</p> 
            </div>

            <div class="block">
                <span class="order">a</span>
                <p class="mb-1">fulfilling the Order;</p> 
            </div>

            <div class="block">
                <span class="order">b</span>
                <p class="mb-1">fulfilling any subsequent order from the Customer; and</p> 
            </div>

            <div class="block">
                <span class="order">c</span>
                <p>sending marketing literature by post or email to the Customer regarding the Supplier’s related products, goods or services.</p> 
            </div>

            <div class="block">
                <span class="order">13.2</span>
                <p>Any Personal Data processed by the Supplier shall be processed in accordance with the requirements of the applicable Data Protection Legislation. Without prejudice to the generality of the foregoing, the Supplier shall not use or disclose the Personal Data to any third party except as set out in these Terms and Conditions.</p> 
            </div>

            <div class="block">
                <span class="order">13.3</span>
                <p>The Customer consents to disclosure by the Supplier of the Customer’s Personal Data to any member of the Supplier’s group, which means its subsidiaries, ultimate holding company and its subsidiaries.</p> 
            </div>

            <div class="block">
                <span class="order">13.4</span>
                <p>The Customer has the right to request that the Supplier does not process its Personal Data for marketing purposes. Such request shall be made by the Customer in writing delivered to the Supplier and the Supplier shall cease processing the Personal Data for marketing purposes within 10 (ten) days of receipt of such written request.</p> 
            </div>

            <div class="block">
                <span class="order">13.5</span>
                <p>The Customer agrees that, as part of the Supplier’s effort to continually improve its Goods and/or Services, the Supplier or any member of the Supplier’s group and/or its distributors may collect data or information, including herd performance data, from the Customer’s ICT systems and/or any other networks for the exchange and processing of (digital) information (“Data”). By permitting Supplier’s employees and/or its distributors to access the Customer’s Data, the Customer grants the Supplier a non-exclusive, perpetual, irrevocable, royalty-free, transferable, worldwide license to use the Data for the Supplier’s business purposes. The Supplier shall not use the Data in a way that discloses the Customer’s individual or business identity to any third party except as set out in these Terms and Conditions.</p> 
            </div>

            <div class="block">
                <span class="order">13.6</span>
                <p>Any data, reports, information or intellectual property created, generated or discovered by the Supplier from the Data (individual or in aggregate) is the sole property of the Supplier, and no ownership rights in such data, reports, information or intellectual property shall transfer to the Customer or any third party.</p> 
            </div>

            <div class="block">
                <span class="order">13.7</span>
                <p>This Section 13 shall survive termination of the Contract.</p> 
            </div>
            
            <div class="block">
                <h2 class="order">14</h2>
                <h2>Cancellation</h2>
            </div>

            <div class="block">
                <span class="order">14.1</span>
                <p>If the Customer:</p> 
            </div>

            <div class="block">
                <span class="order">14.1.1</span>
                <p>fails to pay the Price or any other sums payable under any Contract in full within 14 (fourteen) days after such sums have become due (whether demanded or not); or</p> 
            </div>

            <div class="block">
                <span class="order">14.1.2</span>
                <p>commits material or persistent breach of any other terms of these Terms and Conditions (whether express or implied), then the Customer shall be deemed to have committed a repudiatory breach of the Contract and the Supplier may accept such repudiation and immediately cancel the Contract without liability to the Customer and without prejudice to the Supplier’s other rights and remedies at law or under these Terms and Conditions.</p> 
            </div>

            <div class="block">
                <span class="order">14.2</span>
                <p>In the event of any such cancellation under Section 14.1, the Supplier may cancel or suspend the supply of Services and all further deliveries due under any Contract between the Customer and the Supplier without incurring any liability to the Customer, all outstanding sums in respect of Goods delivered to the Customer shall become immediately due and the provisions of Section 14.1 shall apply.</p> 
            </div>

            <div class="block">
                <span class="order">14.3</span>
                <p>Without limiting its other rights or remedies, each party may terminate the Contract with immediate effect by giving written notice to the other party if the other party commits a material breach of its obligations under this Contract.</p> 
            </div>

            <div class="block">
                <h2 class="order">15</h2>
                <h2>Customer’s Insolvency or Incapacity</h2>
            </div>

            <div class="block">
                <span class="order">15.1</span>
                <p>If the Customer becomes subject to any of the events listed in Section 15.2, or the Supplier reasonably believes that the Customer is about to become subject to any of them and notifies the Customer accordingly, then, without limiting any other right or remedy available to the Supplier, the Supplier may cancel or suspend all further deliveries under the Contract and/or any contract between the Customer and the Supplier without incurring any liability to the Customer, and all outstanding sums in respect of Goods delivered and/or Serviced performed to the Customer shall become immediately due.</p> 
            </div>

            <div class="block">
                <span class="order">15.2</span>
                <p>For the purposes of Section 15.1, the relevant events are:</p> 
            </div>

            <div class="block">
                <span class="order">15.2.1</span>
                <p>if the Customer goes into liquidation, becomes insolvent or has an administrator, receiver or similar officer appointed in respect of all or part of its undertaking (or is the subject of a filing with any court for the appointment of any such officer), or any event occurs, or proceeding is taken, with respect to the Customer in any jurisdiction to which it is subject that has an effect equivalent or similar; or</p> 
            </div>

            <div class="block">
                <span class="order">15.2.2</span>
                <p>the Customer suspends, threatens to suspend, ceases or threatens to cease to carry on all or substantially the whole of its business; or</p> 
            </div>

            <div class="block">
                <span class="order">15.2.3</span>
                <p>the Customer’s financial position deteriorates to such an extent that in the Supplier’s opinion the Customer’s capability adequately to fulfil its obligations under the Contract has been placed in jeopardy; or</p> 
            </div>

            <div class="block">
                <span class="order">15.2.4</span>
                <p>(being an individual) the Customer dies or, by reason of illness or incapacity (whether mental or physical), is incapable of managing his or her own affairs or becomes a patient under any mental health legislation.</p> 
            </div>

            <div class="block">
                <h2 class="order">16</h2>
                <h2>Consequences of Cancellation</h2>
            </div>

            <div class="block">
                <span class="order">16.1</span>
                <p>Without prejudice to the Supplier’s rights to claim damages, the Customer shall, upon any cancellation under Section 14 or 15 immediately pay to the Supplier:</p> 
            </div>

            <div class="block">
                <span class="order">16.1.2</span>
                <p>all arrears of the Price and all other sums accrued due and unpaid at the date of termination, together with interest in accordance with Section 2; and</p> 
            </div>

            <div class="block">
                <span class="order">16.1.2</span>
                <p>any costs (including, without limitation attorney fees) and expenses incurred by the Supplier in locating, recovering or restoring the Goods or collecting any payments due under the Contract or otherwise in obtaining the due performance of the obligations of the Customer under these Terms and Conditions (as applicable).</p> 
            </div>

            <div class="block">
                <span class="order">16.2</span>
                <p>On cancellation of the Contract the Customer shall return all of the Supplier Materials to the Supplier. If the Customer fails to do so, then the Supplier may enter the Customer’s premises and take possession of them. Until they have been returned, the Customer shall be solely responsible for the Supplier Materials.</p> 
            </div>

            <div class="block">
                <h2 class="order">17</h2>
                <h2>Force Majeure</h2>
            </div>

            <div class="block">
                <span class="order">17.1</span>
                <p>Neither party shall be liable for any failure or delay in performing its obligations under the Contract to the extent that such failure or delay is caused by a Force Majeure Event. A Force Majeure Event means any event beyond a party’s reasonable control, which by its nature could not have been foreseen, or, if it could have been foreseen, was unavoidable, including strikes, lock-outs or other industrial disputes (whether involving its own workforce or a third party’s), failure of energy sources or transport network, acts of God, war, terrorism, riot, civil commotion, interference by civil or military authorities, national or international calamity, armed conflict, malicious damage, breakdown of plant or machinery, nuclear, chemical or biological contamination, explosions, collapse of building structures, fire, floods, earthquakes, epidemics or similar events, natural disasters or extreme adverse weather conditions, or default of suppliers or subcontractors. The provisions of this Section shall not apply to any payment obligations.</p> 
            </div>

            <div class="block">
                <span class="order">17.2</span>
                <p>A party claiming to be unable to perform its obligations in the circumstances set out in condition 17.1 must immediately notify the other party of the nature and extent of the circumstances in question.</p> 
            </div>

            <div class="block">
                <h2 class="order">18</h2>
                <h2>Inspection, notifications, and claims</h2>
            </div>

            <div class="block">
                <span class="order">18.1</span>
                <p>If the Goods and/or Services do not conform to the Order (Non-Conformity), then the Customer can only make a claim of Non-Conformity if the Customer has notified the Supplier thereof in writing within as short a period as is practicable in the circumstances, or at least within a period of 10 Business Days, after the Goods and/or Services have been delivered to the Customer.</p> 
            </div>

            <div class="block">
                <span class="order">18.2</span>
                <p>Any notices shall contain an accurate description of the (alleged) Non-Conformity of the Goods and/or Services. Neither the provision of a notice to the Supplier, nor the institution of a claim in any other manner shall relieve the Customer from its payment obligations.</p> 
            </div>

            <div class="block">
                <span class="order">18.3</span>
                <p>If the Customer makes a claim of Non-Conformity, then the Customer shall be obliged to leave the Goods and/or Services in an unaltered condition (and for semen have not been used yet in the process of creating progeny) until the Supplier has investigated the complaints. The right to make a claim of Non-Conformity expires if the Goods and/or Services are no longer in the state in which they were delivered.</p> 
            </div>

            <div class="block">
                <span class="order">18.4</span>
                <p>If it has been agreed that the Customer and/or a third party shall investigate the Goods and/or Services, and the Customer has not made use of this within 10 Business Days after the Customer has been notified of the opportunity to do so, then the Goods and/or Services shall be deemed to have been definitively accepted by the Customer.</p> 
            </div>

            <div class="block">
                <span class="order">18.5</span>
                <p>No claims shall in any event be made against the Supplier if the Goods and/or Services have been handled, transported, stored or processed by or for the Customer incorrectly or contrary to any instructions given by or on behalf of the Supplier.</p> 
            </div>

            <div class="block">
                <span class="order">18.6</span>
                <p>A Non-Conformity against which no claim has been lodged within 10 Business Days in the required manner specified above, will be regarded as having been unconditionally accepted by the Customer.</p> 
            </div>

            <div class="block">
                <span class="order">18.7</span>
                <p>Returning the Goods and/or Services or any part thereof, can only take place after prior written permission from the Supplier, under the conditions to be determined by the Supplier.</p> 
            </div>

            <div class="block">
                <h2 class="order">19</h2>
                <div>
                    <h2>Product Recall</h2>
                    <p>The Supplier shall be entitled to recall or withdraw, and to direct the Customer to promptly remove from the market or the Customer’s possession, any Goods and/or Services that contain/include defects, or in which a defect threatens to manifest itself, or in the event the Supplier determines that an event, incident or circumstance has occurred which may result in the need for a recall or withdrawal. The Customer shall promptly comply with any such direction/instructions. The Customer shall return the Goods and/or Services to the Supplier under the conditions to be determined by the Supplier. The Customer shall not disclose or make available to any third party any information related to the recall or withdrawal of the Goods and/or Services.</p>
                </div>
                
            </div>            

            <div class="block">
                <h2 class="order">20</h2>
                <h2>General</h2>
            </div>

            <div class="block">
                <span class="order">20.1</span>
                <p><u>Assignment and subcontracting</u>. The Supplier may at any time assign, transfer, charge, subcontract or deal in any other manner with all or any of its rights or obligations under the Contract. The Customer may not assign, transfer, charge, subcontract or deal in any other manner with all or any of its rights or obligations under the Contract without the prior written consent of the Supplier.</p> 
            </div>

            <div class="block">
                <span class="order">20.2</span>
                <p><u>Severance</u>. If any court or competent authority finds that any provision of the Contract (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of the Contract shall not be affected. If any invalid, unenforceable or illegal provision of the Contract would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.</p> 
            </div>

            <div class="block">
                <span class="order">20.3</span>
                <p><u>Waiver</u>. A waiver of any right or remedy under the Contract is only effective if given in writing and shall not be deemed a waiver of any subsequent breach or default. No failure or delay by a party to exercise any right or remedy provided under the Contract or by law shall constitute a waiver of that or any other right or remedy, nor shall it preclude or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</p> 
            </div>

            <div class="block">
                <span class="order">20.4</span>
                <p><u>Third party rights</u>. A person who is not a party to the Contract shall not have any rights under or in connection with it.</p> 
            </div>

            <div class="block">
                <span class="order">20.5</span>
                <p><u>Variation of the Contract</u>. Any variation to the Contract, including the introduction of any additional terms and conditions, shall only be binding when agreed in writing and signed by the Parties.</p> 
            </div>

            <div class="block">
                <span class="order">20.6</span>
                <p><u>Rights Cumulative</u>. The rights of the Supplier under these Terms and Conditions are additional and without prejudice to any other rights and remedies available to it either in these Terms and Conditions or provided or permitted by law or equity.</p> 
            </div>

            <div class="block">
                <span class="order">20.7</span>
                <p><u>Notices</u>. Any notice or other document to be served under these Terms and Conditions or other communication to any party must be in writing and in the English language, and may be delivered or sent by pre-paid first class letter post to the party to be served at that party’s address above or at such other address or number as that party may from time to time notify in writing to the other party to these Terms and Conditions. Any notice or document shall be deemed served: if delivered, at the time of delivery; or if posted, 48 hours after posting.</p> 
            </div>

            <div class="block">
                <span class="order">20.8</span>
                <p><u>Invalidity</u>. The invalidity, illegality or unenforceability of any provision of these Terms and Conditions shall not affect the other provisions.</p> 
            </div>

            <div class="block">
                <span class="order">20.9</span>
                <p><u>Governing Law and jurisdiction</u>. The Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, Wisconsin law, and the Parties irrevocably submit to the exclusive jurisdiction of the courts of Wisconsin, USA. The application of the 1980 Vienna Convention on the International Sale of Goods is included.</p> 
            </div>            

        </div>
    </div>
</div>
