import { Component, OnInit, ViewChild } from '@angular/core';
import { BullSearchService } from '../services/bull-search.service';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { Product } from '../models/product.model';
import { ShoppingCartItem } from '../models/shopping-cart-item.model';
import { environment } from 'src/environments/environment';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  permalink: string;
  product: Product;
  conformationDaughters: number;
  conformationHerds: number;
  productionDaughters: number;
  productionHerds: number;
  quantity = 10;
  valid = true;
  bsUrl = environment.bsUrl;

  @ViewChild('pdf', { static: false }) pdf: PDFExportComponent;

  constructor(private _bsService: BullSearchService, public scService: ShoppingCartService,
    private _route: ActivatedRoute, private _router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.initializeParams();
    this.loadData(this.permalink);
    this.translate.use('en');
  }

  initializeParams() {
    const paramMap = this._route.snapshot.paramMap;
    this.permalink = paramMap.get('permalink');
  }

  loadData(permalink: string) {
    this._bsService.getBull(permalink).subscribe((response) => {
      this.product = response;
      if (this.product) {
        if (this.product.BullImage == '/Content/BullSearch/Images/NoImageAvailable_202109/NoImageAvailable.jpg') {
          this.product.BullImage = '/Uploads/NoImageAvailable.jpg'
        }
        if (this.product.DaughersInHerdsForType) {
          const splitted = this.product.DaughersInHerdsForType.split('/');
          if (splitted.length > 1) {
            this.conformationDaughters = +splitted[0];
            this.conformationHerds = +splitted[1];
          }
        }
        if (this.product.DaughtersInHerdsForProduction) {
          const splitted = this.product.DaughtersInHerdsForProduction.split('/');
          if (splitted.length > 1) {
            this.productionDaughters = +splitted[0];
            this.productionHerds = +splitted[1];
          }
        }
      }
    });
  }

  getBarWidth(value: number) {
    let width = 0;
    if (value > 2 || value < -2) {
      width = 125;
    } else {
      width = Math.abs(value) / 2 * 100;
    }
    return `${width}px`;
  }

  addToCart() {
    this.valid = true;
    setTimeout(() => {
      if (this.isValid()) {
        const scItem = new ShoppingCartItem(this.product.SemenCode,
          this.product.IntRegNum,
          this.product.FullName,
          this.quantity,
          this.product.Price);
        this.scService.addItem(scItem);
        this._router.navigateByUrl('/purchase-our-sires/shopping-cart');
      }
    }, 100);
  }

  isValid() {
    this.valid = true;
    if (!this.quantity || this.quantity < 10) {
      this.valid = false;
    }
    else {
      try {
        if (this.quantity % 10 !== 0) {
          this.valid = false;
        }
      }
      catch {
        this.valid = false;
      }
    }
    return this.valid;
  }

  public exportPdf(): void {
    this.pdf.saveAs(this.product.ShortName+'.pdf');
  }

}
