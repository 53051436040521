import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PurchaseOurSiresComponent } from './purchase-our-sires/purchase-our-sires.component';
import { ProductComponent } from './product/product.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CheckoutGuard } from './guards/checkout.guard'
import { CatalogComponent } from './catalog/catalog.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'purchase-our-sires', component: PurchaseOurSiresComponent },
  { path: 'purchase-our-sires/shopping-cart', component: ShoppingCartComponent },
  { path: 'purchase-our-sires/checkout', component: CheckoutComponent, canActivate: [CheckoutGuard], runGuardsAndResolvers: 'always' },
  { path: 'purchase-our-sires/:permalink', component: ProductComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'catalog', component: CatalogComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'news-and-events', component: NewsAndEventsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions-of-sale', component: TermsAndConditionsComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: '404', component: NotFoundComponent},
  { path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
