<section class="container-fluid top mb-5">
    <div class="row">
        <div class="col">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-6">

                        <div class="top-content-container d-flex flex-column align-items-center align-items-sm-start">
                            <img class="img-fluid my-4" src="/assets/images/jetstream.png">
                        </div>
                        <div class="top-content-container d-flex flex-md-row flex-column align-items-center align-items-md-start">
                            <div class="top-content-container-buy d-flex flex-column align-items-center align-items-md-start">
                                <h2>Now is the time to</h2>
                                <h1>Buy Online</h1>
                                <!-- <p class="my-2 text-center text-sm-left">
                                    In the USA we are proud to partner with Dairy Farmers of America and make our product
                                    available to all their members coast to coast, contact your local DFA associate for more
                                    details.
                                </p> -->
                                <a mat-button class="blue-button my-3" routerLink="/purchase-our-sires">Purchase Sires</a>
                            </div>
                            <!-- <div class="top-content-container-msg d-flex flex-column align-items-center align-items-md-start">
                                <h5 class="highlight">
                                    <span class="nowrap">July is Ice Cream Month</span> <br>
                                    <span class="nowrap">Celebrate the savings,</span> <br>
                                    <span class="nowrap">Shop On-line</span><br>
                                </h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 col-xl-6">
                        <div class="text-center">
                            <img class="image-dairy" src="assets/images/Jetstream-December-Sire-Summary-2024.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid featured-bulls mt-5">
    <div class="container">
        <div class="row pb-5 justify-content-center">
            <div class="col-12 col-md-4 px-2 px-lg-5 my-3" *ngFor="let p of featuredBulls">
                <app-featured-bull [product]="p"></app-featured-bull>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid top-gtpi">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 title">
                <h1>Top 5 GTPI</h1>
            </div>
            <div class="col-12 col-md-6 bulls">
                <div>
                    <div *ngFor="let p of topFive; index as i;" class="position-{{i + 1}} mb-3">
                        <span class="mr-2">{{p.ShortName}}</span>
                        <span class="mr-2">{{p.TPI | positiveSign}}</span>
                        <a routerLink="/purchase-our-sires/{{p.permalink}}">Read statistics</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="portfolio">
            <h1>New December 2024 Sire Portfolio</h1>
            <h3>Available NOW on ISSUU.com</h3>
            <a mat-button class="blue-button"
                href="https://issuu.com/jetstreamgenetics/docs/jet_sire_portfolio_12-24_masterproof" target="_blank"
                rel="noreferrer noopener external">
                View Catalog
            </a>
        </div>
    </div>
</section>
