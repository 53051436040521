<div class="container py-3 institutional-page">
    <div class="row">
        <div class="col d-flex justify-content-center">
            <mat-card class="my-5">
              <h1 class="mb-3">Contact us</h1>
                <div class="row">
                  <div class="col-md-7">
                    <mat-card-content>
                      <form [formGroup]="contactForm" class="d-flex flex-column">
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>Name</mat-label>
                                      <input matInput [formControl]="contactForm.controls.name" required>
                                      <mat-error *ngIf="contactForm.controls.name.errors?.required">required field
                                      </mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>E-mail</mat-label>
                                      <input matInput [formControl]="contactForm.controls.email" required>
                                      <mat-error *ngIf="contactForm.controls.email.errors?.required">required field</mat-error>
                                      <mat-error *ngIf="contactForm.controls.email.errors?.email">invalid e-mail</mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>Company</mat-label>
                                      <input matInput [formControl]="contactForm.controls.company" required>
                                      <mat-error *ngIf="contactForm.controls.company.errors?.required">required field</mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>Country</mat-label>
                                      <input matInput [formControl]="contactForm.controls.country" required>
                                      <mat-error *ngIf="contactForm.controls.country.errors?.required">required field</mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>Telephone</mat-label>
                                      <input matInput [formControl]="contactForm.controls.telephone">
                                  </mat-form-field>
                              </div>
                              <div class="col-12 col-md-6">
                                  <mat-form-field color="accent">
                                      <mat-label>Comments</mat-label>
                                      <input matInput [formControl]="contactForm.controls.comments" required>
                                      <mat-error *ngIf="contactForm.controls.comments.errors?.required">required field</mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="row mb-4">
                              <div class="col">
                                  <div class="recaptcha-container">
                                      <span class="mr-2"
                                          [class.error]="contactForm.controls.recaptcha.touched && contactForm.controls.recaptcha.errors?.required">*
                                      </span>
                                      <re-captcha formControlName="recaptcha" #recaptcha></re-captcha>
                                  </div>
                              </div>
                          </div>
                          <button type="button" mat-flat-button class="blue-button py-2 align-self-md-center" (click)="submit()">Send Message</button>
                      </form>
                      <h2 class="success d-flex align-items-center" *ngIf="success">
                          <mat-icon class="mr-2">check</mat-icon>
                          <span>Message sent successfully.</span>
                      </h2>
                      <h2 class="error d-flex align-items-center" *ngIf="error">
                          <mat-icon class="mr-2">error_outline</mat-icon>
                          <span>An error has occurred. Please try again.</span>
                      </h2>
                  </mat-card-content>
                  </div>
                  <div class="col-md-5">
                    <div class="contactFormat">
                      <p>
                        For all Inquires please contact.
                        <br>
                        Mr. Roger Turner
                        <br>
                        Manager Jetstream Genetics
                        <br>
                        <a href="mailto:Roger.turner@jetstreamgenetics.com">Roger.turner@jetstreamgenetics.com</a>
                        <br>
                        608-770-0012 USA
                      </p>
                      <p>
                        In the UK please Contact.
                        <br>
                        Mr. Alan Timbrell
                        <br>
                        <a href="mailto:Alan.Timbrell@jetstreamgenetics.com">Alan.Timbrell@jetstreamgenetics.com</a>
                        <br>
                        44 7889 044070
                      </p>
                      <p>
                        In Australia please contact.
                        <br>
                        Phil McKenna
                        <br>
                        <a href="mailto:phillip.mckenna@jetstreamgenetics.com">phillip.mckenna@jetstreamgenetics.com</a>
                        <br>
                        +61 438 777 411
                      </p>
                    </div>
                  </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
