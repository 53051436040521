<div class="container py-3 rules-page">
    <div class="row">
        <div class="col">
            <h1>Disclaimer</h1>
            <p>Jetstream Genetics exercises the utmost care in producing, composing, editing and distributing the information on this website.
                Jetstream Genetics is not liable for damages resulting from use of any apparent misstatements and errors in this 
                information as stated on this website.
            </p>

            <h2>Website Terms and Conditions of Use</h2>
            <p class="font-weight-bold text-uppercase">
                Please read these website terms and conditions of use (“terms of use”) before using our website.
                These terms of use govern your access to and use of this website and set forth the legally binding terms and conditions for your use of this website.
                If you do not agree to all of the terms of use, do not access or use this website.
                By accessing or using this website, you agree to be bound by the terms of use and any additional terms, conditions, and policies referred to herein.
            </p>

            <h2>License and Ownership of Intellectual Property</h2>
            <p>
                Any and all intellectual property rights associated with the website and its contents, including but not limited to source codes, graphics, icons, and text contained on this website (“Intellectual Property”) is the sole property of Jetstream Genetics, LLC (“Jetstream”), its affiliates or third parties. The content of this website is intended for personal use only, and protected by Copyright and Trademark laws.
            </p>
            <p>
                Except as otherwise expressly authorized by these Terms of Use, you may not reproduce, modify, lease, loan, license, sell, publish, duplicate, create derivative works from, upload, display, transmit, distribute, or otherwise exploit any Intellectual Property or any portion of this website in any way without Jetstream’s prior written consent. You further agree not to copy, use, or otherwise infringe upon or dilute the trademarks or service marks of Jetstream, and agree not to alter or remove any trademark, copyright or other notice from any copies of the content on this website. Except as expressly provided herein, Jetstream does not grant you any express or implied rights to Jetstream’s or any third party’s Intellectual Property. Any party who seeks an expanded license or use of the materials provided on this website should contact the administrator of this website directly.
            </p>
            <p>
                Jetstream grants users a limited, personal, nontransferable, nonsublicensable, revocable, nonexclusive license to access and use this website to view, browse, print, and occasionally download small amounts of Intellectual Property appearing on this Website for personal, non commercial use. You must keep intact all copyright, trademark, and other notices contained in your personal copies.
            </p>

            <h2>Restriction on Use and Prohibited Activities:</h2>
            <p>
                In addition to other restrictions set forth in these Terms of Use, you agree that you will not post, e-mail, link, or otherwise make available any material that: (1) is unlawful, harmful, threatening, abusive, harassing, defamatory, pornographic, and/or invasive of another’s privacy; (2) is false, deceptive, misleading, deceitful, and/or that impersonates any person or entity or falsely misrepresents an affiliation with a person or entity; (3) is patently offensive in that it promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; (4) involves the furtherance or transmission of “junk mail,” “spam,” or unsolicited mass mailing of any kind; (5) promotes, displays, or promulgates pornographic or sexually explicit material of any kind; (6) constitutes abusive, threatening, harassing, or “stalking” communications; (7) includes a source code, file, program, or software designed to interrupt, destroy, limit, copy, or control this website’s platform, and/or that attempts to collect personal identifying information and data about users, through the use of any robot, spider, crawler, data mining tools, or other automatic device, and/or a manual process; (8) decompiles, reverse engineers, disassembles, or otherwise attempts to obtain the source code for the platform; or (9) imposes an unreasonable or disproportionately large load (flooding) on the website’s hardware and software infrastructure to effect the quality of or quantity of material on the site.
            </p>
            <p>
                You further agree that you will not (1) disguise the origin of information transmitted through this website; (2) place false or misleading information on this website; or (3) use or access any service, information, application, or software available through this website in a manner not expressly permitted by Jetstream.
            </p>

            <h2>Disclaimer of Warranties</h2>
            <p class="font-weight-bold text-uppercase">
                JETSTREAM MAKES NO REPRESENTATIONS ABOUT THE RESULTS TO BE OBTAINED FROM USING THIS WEBSITE OR THE INFORMATION CONTAINED IN THIS WEBSITE.
                AN INDIVIDUAL’S USE OF AND/OR RELIANCE UPON ANY SERVICE OR INFORMATION ON THIS WEBSITE IS AT THAT INDIVIDUAL’S SOLE RISK. INFORMATION IS MADE AVAILABLE ONLY ON AN “AS IS” AND “AS AVAILABLE’ BASIS. JETSTREAM MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS, WARRANTIES, OR GUARANTEES WITH REGARD TO THE APPROPRIATENESS, ACCURACY, SUFFICIENCY, CORRECTNESS, VERACITY, VALUE, COMPLETENESS, OR TIMELINESS OF THE CONTENT ON THIS WEBSITE AND EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO THE WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </p>
            <p>
                Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions of implied warranties may not be applicable under certain circumstances. Under these circumstances, all other exclusions remain in force.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
                Jetstream could not and does not monitor all of the content posted or transmitted by its users. Jetstream expressly reserves the right, however, to monitor, delete, move, or edit any content submitted to this site without notice, at its sole discretion. As a condition of use, therefore, each user expressly agrees the site administrator, Jetstream, its officers, its subsidiaries, or their officers, employees, agents, or representatives, to the fullest extent permitted by law, (i) will not be liable for any claims, actions, or judgments arising out of or related to any content, and (ii) may monitor, delete, move, or edit any text, graphics, or other materials submitted to this website, without notice at any time for any reason, without liability. Each user expressly understands and agrees that he/she/it shall be solely responsible for any and all materials submitted to this site.
            </p>

            <h2>Third Party Links</h2>
            <p>
                Jetstream may include links directly to third-party websites. Jetstream does not endorse, sponsor, or guarantee linked websites and is not responsible in any way for any content, information, products, advice, or services made available through linked websites. Users who click on links to third-party sites should be aware they are leaving this website and shall be subject to the privacy policy and terms of service of any third-party website the user selects. Website operators who wish to link to this site may only link to the home page. Jetstream reserves the right, however, to reject or terminate any links to the content of this site.
            </p>

            <h2>User Representations and Indemnifications</h2>
            <p>
                You, as the user, shall not post any content or material on this website unless you first represent and warrant (i) the submitted materials do not and/or will not violate any applicable federal, state, or local law or regulation; (ii) the materials submitted do not and/or will not infringe or misappropriate any copyright, trademark, trade secret, patent, or any other intellectual property right of any third party, including the rights of privacy or publicity, and (iii) you have the necessary authority, rights, and permission to post the material submitted.
            </p>
            <p>
                If you post any material in violation of any of the rules identified above, you agree to release, defend, indemnify, and hold Jetstream, its respective officers, directors, employees, agents, affiliates, associates, and/or contractors harmless from and against any and all claims, costs, demands or expenses, and or consequential damages, including attorneys’ fees, arising from any distribution, publication, deletion, movement, editing, or other use of the materials provided.
            </p>

            <h2>Choice of Laws and Consent to Jurisdiction</h2>
            <p>
                To the fullest extent permitted by law, these Terms of Use are be governed by and construed in accordance with the laws of the State of Illinois without regard to its conflicts of law provisions. To the fullest extent permitted by law, you expressly agree that any cause of action you may have with respect to the website must be exclusively filed in the federal or state courts located in Sangamon County, Illinois, within one (1) year after the cause of action arises or the cause is barred. You hereby consent to personal jurisdiction in the federal and state courts in Sangamon County, Illinois, and waive any objection based on forum non conveniens. As a condition of using this website, you agree that all causes of action arising out of or connected with this website shall be resolved individually, without resort to any form of class action.
            </p>

            <h2>Severability</h2>
            <p>
                If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision of these Terms of Use will be enforced to the maximum extent permissible so as to affect the intent of the parties, and the remainder of these Terms of Use will continue in full force and effect. A failure by Jetstream to enforce any provision of these Terms of Use will not be deemed a waiver of future enforcement of any provision. These Terms of Use constitutes the entire agreement between the parties. Neither this Terms of Use, nor any terms and conditions contained herein, shall be construed as creating a partnership, joint venture, or agency relationship or granting a franchise between the parties.
            </p>

            <h2>Changes to these Terms of Use</h2>
            <p>
                Jetstream reserves the right to occasionally amend, modify, or update these Terms of Use at any time without prior notice. For this reason, you should review these Terms of Use each time you purchase products or use this website or its contents.
            </p>

            <h2>Contact Information</h2>
            <p>
                Jetstream welcomes your comments regarding these Terms of Use. If you have questions or suggestions, or if you believe that Jetstream has not adhered to these Terms of Use, or if you want to report a violation of these Terms of Use please contact Jetstream at info@jetstreamgenetics.com.
            </p>
        </div>
    </div>
</div>