import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positiveSign'
})
export class PositiveSignPipe implements PipeTransform {

  transform(value: number): string {    
    if (value === 0) {
      return value.toString();
    }

    if (!value) {
      return null;
    }
    return value > 0 ? `+${value}` : value.toString();
  }

}
