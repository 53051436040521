<h1 mat-dialog-title class="mb-1">Select top traits</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
    <p class="my-1">Select the most important traits to display:</p>
    <mat-checkbox [checked]="allChecked"
                  [indeterminate]="someChecked()"
                  (change)="setAll($event.checked)"
                  color="primary">                  
                  All      
    </mat-checkbox>
    <div class="d-flex flex-wrap">
        <mat-checkbox *ngFor="let cd of data.columnsDefinitions | noSticky " class="mx-3 my-2" color="primary"
            [(ngModel)]="cd.show" (ngModelChange)="updateAllChecked()">
            {{cd.header}}
        </mat-checkbox>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="warn" [mat-dialog-close]="">Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="data.columnsDefinitions">Ok</button>
</div>