import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catalog-dairy1p',
  templateUrl: './catalog-dairy1p.component.html',
  styleUrls: ['./catalog-dairy1p.component.scss']
})
export class CatalogDairy1pComponent implements OnInit {
  @Input() product!: any;
  public conformationDaughters: number;
  public conformationHerds: number;
  public productionDaughters: number;
  public productionHerds: number;
  bsUrl = environment.bsUrl;
  
  constructor() { }

  ngOnInit(): void {
    this.formatData();
  }

  formatData(){
    if (this.product) {
      if (this.product.BullImage == '/Content/BullSearch/Images/NoImageAvailable_202109/NoImageAvailable.jpg') {
        this.product.BullImage = '/Uploads/NoImageAvailable.jpg'
      }
      if (this.product.DaughersInHerdsForType) {
        const splitted = this.product.DaughersInHerdsForType.split('/');
        if (splitted.length > 1) {
          this.conformationDaughters = +splitted[0];
          this.conformationHerds = +splitted[1];
        }
      }
      if (this.product.DaughtersInHerdsForProduction) {
        const splitted = this.product.DaughtersInHerdsForProduction.split('/');
        if (splitted.length > 1) {
          this.productionDaughters = +splitted[0];
          this.productionHerds = +splitted[1];
        }
      }
    }
  }

  getBarWidth(value: number) {
    let width = 0;
    if (value > 2 || value < -2) {
      width = 125;
    } else {
      width = Math.abs(value) / 2 * 100;
    }
    return `${width}px`;
  }
  
}
