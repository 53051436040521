import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.privacyPolicy);
  }


}
