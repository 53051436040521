import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TraitsDialogData } from '../models/traits-dialog-data.model'

@Component({
  selector: 'app-traits-dialog',
  templateUrl: './traits-dialog.component.html',
  styleUrls: ['./traits-dialog.component.scss']
})
export class TraitsDialogComponent implements OnInit {

  allChecked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TraitsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TraitsDialogData) {}

  ngOnInit(): void {
    // this.allChecked = this.data && this.data.columnsDefinitions
    //   && this.data.columnsDefinitions.filter((cd) => cd.show).length === this.data.columnsDefinitions.length;
    this.updateAllChecked();
  }
  
  someChecked(): boolean {
    if (this.data == null || this.data.columnsDefinitions == null) {
      return false;
    }
    return this.data.columnsDefinitions.filter((cd) => !cd.stick && cd.show).length > 0 && !this.allChecked;
  }

  setAll(show: boolean) {
    this.allChecked = show;
    if (this.data == null || this.data.columnsDefinitions == null) {
      return;
    }
    this.data.columnsDefinitions.filter((cd) => !cd.stick).forEach((cd) => cd.show = show);
  }

  updateAllChecked() {
    this.allChecked = this.data && this.data.columnsDefinitions && this.data.columnsDefinitions.every((cd) => cd.show);
  }

}
