<main class="container py-3">
    <div class="row">
        <div class="col">
            <h1>Page Not Found</h1>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex flex-column align-items-center py-5 empty-cart">
            <mat-icon class="mt-5">link_off</mat-icon>
            <h2>We are sorry, but the page you are looking for cannot be found.</h2>
            <ul>
                <li>If you typed the URL directly, please make sure the spelling is correct.</li>
                <li>If you clicked on a link to get here, we must have moved the content.</li>
                <li>If you are not sure how you got here, return to our <a routerLink="/">homepage</a>.</li>
            </ul>
        </div>
    </div>
</main>