import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  constructor(private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.disclaimer);
  }

}
