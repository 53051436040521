import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jetstream-us';
  
  onRouteActivate(event: any): void {
    const matSideNavContent = window.document.getElementById('matSidenavContent') as HTMLElement;
    if (matSideNavContent) {
      matSideNavContent.scrollTop = 0;
    }
  }
}
