import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = false;

  constructor() { }

  show() {
    setTimeout(() => {
      this.loading = true;  
    }, 100);    
  }

  hide() {
    setTimeout(() => {
      this.loading = false;
    }, 100);    
  }
}
