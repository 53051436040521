import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ContactDto } from '../models/contact-dto.model';
import { JetstreamService } from '../services/jetstream.service';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('recaptcha') recaptcha: RecaptchaComponent;

  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    telephone: new FormControl(''),
    comments: new FormControl('', [Validators.required]),
    recaptcha: new FormControl(null, Validators.required)
  });

  success = false;
  error = false;

  constructor(private _jsService: JetstreamService, private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.contactUs);
  }

  submit() {
    this.success = false;
    this.error = false;
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      const contact = new ContactDto();
      contact.name = this.contactForm.controls["name"].value;
      contact.email = this.contactForm.controls["email"].value;
      contact.company = this.contactForm.controls["company"].value;
      contact.country = this.contactForm.controls["country"].value;
      contact.telephone = this.contactForm.controls["telephone"].value;
      contact.comments = this.contactForm.controls["comments"].value;
      this._jsService.sendMessage(contact).subscribe(() => {
        this.success = true;
        this.contactForm.reset();
        this.recaptcha.reset();
        this.contactForm.controls['recaptcha'].markAsUntouched();
      }, error => {
        this.error = true;
        console.log(error);
      });
    }
  }

}
