<div class="container py-3 rules-page">
    <div class="row">
        <div class="col">
            <h1>Privacy Policy</h1>            

            <h2>General Privacy Policy</h2>
            <p>
                Jetstream Genetics, LLC (“Jetstream”) is committed to protecting your privacy and providing you with a safe online experience by using online security measures to protect against the loss, misuse, and alteration of the information under our control. The information below describes the type of information Jetstream collects, how it is used, and the steps taken to protect your personally identifiable information. This Privacy Policy applies only to this website and phone orders. Periodically, Jetstream may update our Privacy and Security policies and encourage you to check this section for any changes.
            </p>
            <p>
                Jetstream will never share, sell, or rent individual personal information with anyone without your advance permission or unless ordered by a court of law. Information submitted to us is only available to employees managing this information for purposes of contacting you or sending you e-mails based on your requesting information.
            </p>
            <p>
                By using Jetstream’s website, you consent to the data practices described in this statement.
            </p>

            <h2>Aggregated Information Collected or Received</h2>
            <p>
                Through this website, Jetstream collects and stores non-personal information in aggregate form. Data is collected and stored for internal statistical purposes. This information is used by Jetstream for its operation, to maintain quality of the services it provides, and to provide general statistics regarding use of its website. Non-personal information includes:
            </p>
            <ul>
                <li>the browser type with which you access the Internet;</li>
                <li>the date and time you accessed Jetstream’s website;</li>
                <li>the Internet address of the website that linked you to Jetstream’s website; and</li>
                <li>information you view while visiting Jetstream’s website.</li>
            </ul>

            <h2>Personal Information Collected or Received</h2>
            <p>
                In specific instances, Jetstream processes personally-identifiable information that you submit via this website, such as name, address, telephone and fax numbers, e-mail address, credit card and purchase information. That information is used to process your purchase. By sending personal information to Jetstream via this website, you acknowledge that Jetstream will process certain personally-identifiable data about you. You therefore consent to our use of that information in accordance with Jetstream’s Privacy Policy.
            </p>
            <p>
                Except as described in this Privacy Policy, Jetstream will not use or otherwise disclose any personally-identifiable information provided through this website. Jetstream does not sell, rent, or lease its customer lists or personal data to third parties. Jetstream does not vouch for, and is not responsible for, the accuracy of data about you that may be supplied to us through your e-mail or through any other submission of personal information through this website.
            </p>

            <h2>Security of Your Personal Information</h2>
            <p>
                Jetstream uses physical, electronic, and managerial measures to provide the personal information in our possession with reasonable protection from accidental loss or destruction, improper use, alteration, or disclosure. Personal information collected or displayed through our website is protected in transit by industry standard encryption processes. Jetstream also limits access to your personal information to employees who Jetstream believes reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs. Jetstream further maintain a policy for employees to safeguard client information designed to prevent unauthorized access, disclosure, or use in any way.
            </p>
            <p>
                Even with safeguards in place, Jetstream cannot guarantee the security of personal information accessible on or transmitted via the Internet. Any visitors who do not want their personal information collected online are offered the opportunity to not submit such information on our website. 
            </p>
            <p>
                Jetstream may release your personal information to a third-party, with or without notice, in order comply with a subpoena or other similar legal procedure, or when Jetstream believes in good faith belief that such action is necessary to: (a) comply with the law, a court order, or other legal obligation; (b) protect and defend the rights or property of Jetstream; (c) act under exigent circumstances to protect the personal safety of users of Jetstream, or the public; or (d) investigate or take action regarding illegal activities, suspected fraud, or violations of Jetstream’s Terms of Use.
            </p>

            <h2>Use of Cookies</h2>
            <p>
                Jetstream’s website uses the standard “cookies” feature of most browser applications to help you personalize your online experience. A “cookie” is a small amount of data sent to your browser from a website server that may be stored on your computer’s hard drive. One of the primary purposes of cookies is to provide a convenience feature to save you time. Jetstream may use cookies to help provide you with a better experience on our site; however, Jetstream does not store personal identifying information in cookies. By setting a cookie on our site, users would not have to log in a password more than once, thereby saving time while on our site.
            </p>
            <p>
                Most Internet browsers allow users to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. Cookies cannot be used to run programs or deliver viruses to your computer. Jetstream encourages you to refer to your Internet browser’s instructions to learn about those functions and options. 
            </p>

            <h2>Links to Other Websites</h2>
            <p>
                This website may contain links to other websites. Jetstream is not responsible for the privacy practices or the content of any other websites to which the Jetstream website links. This Privacy Policy applies only to Jetstream’s website and does not apply to data collected on any other website, including other websites to which this site may link.
            </p>

            <h2>Changes to this Privacy Policy</h2>
            <p>
                Jetstream reserves the right to occasionally amend, modify, or update this Privacy Policy to reflect company and customer feedback. Jetstream encourages you to periodically review this Statement to be informed of how Jetstream is protecting your information.
            </p>

            <h2>Contact Information</h2>
            <p>
                Jetstream welcomes your comments regarding this Privacy Policy. If you have questions or suggestions, or if you believe that Jetstream has not adhered to this Privacy Policy, please contact Jetstream by email at <a href="mailto:info@jetstreamgenetics.com">info@jetstreamgenetics.com</a>. Jetstream will use commercially reasonable efforts to promptly determine and remedy the problem.
            </p>
        </div>
    </div>
</div>
