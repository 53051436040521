import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { OrderDto } from '../models/order-dto.model';
import { ContactDto } from '../models/contact-dto.model';

@Injectable({
  providedIn: 'root'
})
export class JetstreamService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) { }

  placeOrder(order: OrderDto): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/${environment.culture}/order`, order, this.options)
      .pipe(retry(2), catchError(this.handleError));
  }

  sendMessage(contact: ContactDto): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/${environment.culture}/contact`, contact, this.options)
      .pipe(retry(2), catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error( `Backend returned code ${error.status}`)
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Operation error');
  }
}
