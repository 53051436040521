<mat-sidenav-container [hasBackdrop]="true" class="h-100">
  <mat-sidenav #drawer [mode]="'push'">
    <nav class="py-3">
      <mat-nav-list>
        <a class="px-3" mat-list-item routerLink="/purchase-our-sires" (click)="drawer.toggle()">Purchase our sires</a>
        <a class="px-3" mat-list-item routerLink="/contact-us" (click)="drawer.toggle()">Contact Us</a>
        <a class="px-3" mat-list-item routerLink="/about-us" (click)="drawer.toggle()">About Us</a>
        <a class="px-3" mat-list-item routerLink="/news-and-events" (click)="drawer.toggle()">News and events</a>
      </mat-nav-list>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content id="matSidenavContent">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="container">
        <button mat-icon-button class="d-md-none" (click)="drawer.toggle()" color="primary">
          <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/" class="logo">
          <img class="img-fluid" src="/assets/images/jetstream-logo-white.png"/>
        </a>
        <span class="spacer d-none d-md-block"></span>
        <nav class="d-none d-md-block">
          <a routerLink="/purchase-our-sires" routerLinkActive="active">
            <span>Purchase our sires</span>
          </a>
        </nav>
        <nav class="d-none d-md-block">
          <a routerLink="/contact-us" routerLinkActive="active">
            <span>Contact Us</span>
          </a>
        </nav>
        <nav class="d-none d-md-block">
          <a routerLink="/about-us" routerLinkActive="active">
            <span>About Us</span>
          </a>
        </nav>
        <nav class="d-none d-md-block">
          <a routerLink="/news-and-events" routerLinkActive="active">
            <span>Proof Highlights</span>
          </a>
        </nav>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="main-container">
      <router-outlet (activate)="onRouteActivate($event)"></router-outlet>
      <app-footer></app-footer>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
<app-loader></app-loader>
