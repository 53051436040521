<div class="container py-3">
    <div class="row">
        <div class="col-12 col-md-10">
            <h1>{{'CREATEYOURCATALOG' | translate}} {{stepsTitle[this.stepsIndex] | translate}}</h1>
        </div>
        <div class="col-12 col-md-2 mt-4">
          <mat-form-field>
            <mat-label>{{'CatalogLanguage' | translate}}</mat-label>
            <mat-select [(value)]="selectedLanguage" (selectionChange)="changeLanguage($event.value)">
              <mat-option *ngFor="let language of languages" [value]="language.code">
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>

    <div class="col d-none d-md-flex align-items-center">
        <h4 *ngIf="data">{{"THE_CATALOG_WILL_BE_CREATE_WITH" | translate}} {{data.length}} {{'BULLS' | translate}}.</h4>
    </div>

    <div class="row mt-2 catalogContent">

        <!-- Home -->
        <div class="col p-0" *ngIf="stepsList[stepsIndex] == 0">
            <div class="row">
                <div class="col mt-4">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="row">
                            <div class="col">
                            <mat-checkbox [(ngModel)]="catalog.covers"  [disabled]="catalog.onlyBulls">{{'FRONT_AND_BACK_COVERS' | translate}}</mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            <mat-checkbox [(ngModel)]="catalog.tableContents" [disabled]="catalog.onlyBulls">{{'TABLE_OF_CONTENTS' | translate}}</mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            <mat-checkbox [(ngModel)]="checkGeneticSummary"  [disabled]="catalog.onlyBulls">{{'GENETIC_SUMMARY' | translate}}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                        <mat-form-field color="accent" class="w-100">
                            <mat-label>{{'ORDER' | translate}}</mat-label>
                            <mat-select [(ngModel)]="catalog.order">
                            <mat-option *ngFor="let o of orderList" [value]="o.id">
                                {{o.desc}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!-- Pick your cover / Title -->
        <div *ngIf="stepsList[stepsIndex] == 1" class="flex-grow-1">
            <div class="row">

                <div class="col-12 p-0 py-2">

                <div class="col">
                    <mat-form-field color="accent" class="w-100">
                    <mat-label>{{'TITLE' | translate}}</mat-label>
                    <input matInput [(ngModel)]="catalog.title">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field color="accent" class="w-100 p-0 m-0">
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input matInput [(ngModel)]="catalog.name">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field color="accent" class="w-100 p-0 m-0">
                    <mat-label>{{'ADDRESS' | translate}}</mat-label>
                    <input matInput [(ngModel)]="catalog.address">
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field color="accent" class="w-100">
                    <span matPrefix>+1 &nbsp;</span>
                    <mat-label>{{'PHONE' | translate}}</mat-label>
                    <input matInput [(ngModel)]="catalog.phone"  placeholder="555-555-1234">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field color="accent" class="w-100">
                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                    <input type="email" matInput [(ngModel)]="catalog.email" placeholder="Ex. pat@example.com">
                    </mat-form-field>
                </div>
                </div>

            </div>
        </div>

        <!-- Print Catalog -->
        <div *ngIf="stepsList[stepsIndex] == 2" >
            <div class="content py-3 d-flex justify-content-center">
                <img src="{{getCoverByID(catalog.coverId, true)}}" height="350" >
            </div>
        </div>

        
    </div>

    <div class="row mt-2 catalogButtons">
        <!-- Buttons -->
        <div class="d-flex flex-grow-1">
            <div class="flex-grow-1 p-1 text-muted">{{statusMessage | translate}}</div>
            <div class="justify-content-end">
                <button mat-button color="warn" (click)="goBackList()">{{'BACK_TO_THE_LIST' | translate}}</button>

                <button mat-button color="primary" (click)="goBack()" [disabled]="generatingCatalog" *ngIf="stepsList[stepsIndex] > 0">{{'BACK' | translate}}</button>

                <button mat-button color="primary" (click)="goNext()" [disabled]="generatingCatalog">
                    <span *ngIf="!generatingCatalog">{{nextMessage | translate}}</span>
                    <div class="d-flex flex-row" *ngIf="generatingCatalog">
                      <span >{{'CREATING' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>

</div>

<div class="PDFComp">
<kendo-pdf-export #pdf margin="0cm" paperSize="A4" forcePageBreak=".pageBreak" >

    <div id="cover" class="DivCover" *ngIf="catalog.covers" style="background-image: url(' {{getCoverByID(catalog.coverId, false)}}'); ">
        <div class="title">
          {{catalog.title}}
        </div>
        <div class="subtitle">
          {{getDate()}}
        </div>
    </div>

    <div id="tableContents" class="DivCover" *ngIf="catalog.tableContents">
        <div *ngFor="let item of catalog.items; index as i">

            <div class="title pageBreak" *ngIf="(i % contentListPageSize) == 0">
              {{'TABLE_OF_CONTENTS' | translate}}
            </div>

            <div class="content">
              <div class="d-flex justify-content-between" >
                <div>{{item.description}}</div>
                <div class="flex-grow-1 fillDot"></div>
                <div class="pageDiv">{{item.page}}</div>
              </div>
            </div>

          </div>
    </div>

    <div class="summary-content" *ngIf="geneticSummary && checkGeneticSummary">
        <div class="pageBreak"></div>
        <div class="summary-title"><span>{{'GENETIC_SUMMARY' | translate}}</span></div>
        <div class="summary-header">
            <table class="w-100">
                <tr class="text-center">
                    <td></td>
                    <td class="title">{{'PRODUCTION' | translate}}</td>
                    <td class="title">{{'HEALTH' | translate}}</td>
                    <td class="title">{{'CONFORMATION' | translate}}</td>
                </tr>
                <tr class="text-center">
                    <td class="text-left">
                        <span class="title">% REL</span>
                    </td>
                    <td>
                       <span>{{geneticSummary.Prod_Rel}}</span> 
                    </td>
                    <td>
                        <span>{{geneticSummary.Health_Rel}}</span>
                    </td>
                    <td>
                        <span>{{geneticSummary.Conf_Rel}}</span>
                    </td>
                </tr>
                <tr class="text-center">
                    <td class="text-left">
                        <span class="title">% TeamRel</span>
                    </td>
                    <td>
                       <span>{{geneticSummary.Prod_TeamRel}}</span> 
                    </td>
                    <td>
                        <span>{{geneticSummary.Health_TeamRel}}</span>
                    </td>
                    <td>
                        <span>{{geneticSummary.Conf_TeamRel}}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="summary-block-header">
                    <span class="title">{{'PRODUCTION' | translate}}</span>
                </div>
                <table class="w-100 chart" >
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{{'Average' | translate}}</td>
                    </tr>
                    <tr class="line-tr" *ngFor="let product of geneticSummary.Production">
                        <td>{{product.Trait}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.Graph >= 0, 'negative': product.Graph < 0}"
                                    [style.width]="getBarWidth(product.Graph)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.Average}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class="col-6">
                <div class="summary-block-header">
                    <span class="title">{{'HEALTH' | translate}}</span>
                </div>
                <table class="w-100 chart" >
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{{'Average' | translate}}</td>
                    </tr>
                    <tr class="line-tr" *ngFor="let product of geneticSummary.Health">
                        <td>{{product.Trait}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.Graph >= 0, 'negative': product.Graph < 0}"
                                    [style.width]="getBarWidth(product.Graph)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.Average}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class="col-6">
                <div class="summary-block-header">
                    <span class="title">{{'CONFORMATION' | translate}}</span>
                </div>
                <table class="w-100 chart" >
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{{'Average' | translate}}</td>
                    </tr>
                    <tr class="line-tr" *ngFor="let product of geneticSummary.Conformation">
                        <td>{{product.Trait}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.Graph >= 0, 'negative': product.Graph < 0}"
                                    [style.width]="getBarWidth(product.Graph)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.Average}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        
        
    </div>

    <div class="content1p" *ngFor="let item of items_bulls_dairy | sortBy:'asc':'{{getOrderField()}}'">
        <div class="pageBreak"></div>
        <app-catalog-dairy1p [product]="item" *ngIf="item"></app-catalog-dairy1p>
    </div>

    <div class="pageBreak" *ngIf="catalog.covers"></div>
    <div id="backCover" class="DivCover" *ngIf="catalog.covers" style="background-image: url(' {{getBackCover()}}'); ">
      <div class="text">
        {{catalog.name}} <br>
        {{catalog.title}} <br>
        {{catalog.address}} <br>
        {{catalog.phone}} <br>
        {{catalog.email}}
      </div>
  </div>

</kendo-pdf-export>
</div>


