<div class="container py-3 institutional-page">
    <div class="row">
        <div class="col">
            <img class="img-fluid my-4 rounded" src="/assets/images/news_events_202412.jpg">
            <h1>December 2024 Sire Highlights for Jetstream Genetics</h1>
        </div>
    </div>
</div>
<div class="container-fluid grey-bg">
    <div class="container institutional-page">
      <main class="row">
        <div class="col">
          <p>
            Madison, Wis. — The December 2024 Sire Summary includes outstanding results for the Jetstream Genetics lineup including exciting milking daughters from reliable Performance Proven Sires, new-release Jetset Priority Genomic Sires from renowned cow families, showring standouts with Custom Cut Type Sires, as well as opportunities with fertility specialist Reprojet Sires and the unique Robotic Sires.
          </p>
          <p>
            Priority Jetset Genomic Sire highlights:
          </p>
          <p>
            <b>734HO00166 PEAK ZOWIE-ET</b> (Mirrorimage x AltaKevlow x Marus) ZOWIE a new release sire and new to the Top GTPI position at #1 GTPI +3266. Being A2A2 and +1246 NM$ makes him elite amongst the elite. Along with his outstanding production at +1130 Milk, +155 Fat and +71 Protein he is also Low SCS and Low SCE. ZOWIE is a Mirrorimage x AltaKevlow x Marus x VG86 Huey x VG85 Granite x Millington x EX90 Dorcy x EX91 Auden x EX91 Outside x EX90 Rudolph x EX94 3E Mandel x EX93 Blackstar x Valiant x Jemini x Talent.
            <br>ZOWIE will transmit those balanced cow with strongly attached udders, correct rump structure, along with those desired health traits.
            <br>ZOWIE is housed in the USA and qualifies for all international export markets.
          </p>
          <p>
            <b>734HO00163 PEAK LUCRATIVE-ET</b> (Marlon x AltaMagnifique x Marus) LUCRATIVE delivers a Peak to the lineup as our No. 2 GTPI Sire for Dec 2024 with +3177 GTPI and +1.86 PTAT.
            <br>LUCRATIVE is a complete package with +1763 Milk, +1093 NM$ with +75 Protein backed by a balanced linear trait type proof as well as +1.83 UDC. LUCRATIVE is a Marlon x AltaMagnifique x Marus x Positive x Jedi x Yoder x VG87 McCutchen x VG87 Snowman x VG86 Planet x VG89 Goldwyn x high profile Durham Lylehaven Lila Z EX94 x EX94 Formation x EX94 Starbuck. The family of LUCRATIVE has delivered breed impact sires; Liquid Gold, Lexor, Lynden, and Lazer. 
            <br>LUCRATIVE is next in line for this legendary status, his profile will breed those modern dairymen cows with High Wide Rear udders and strongly attached fore udders. With sloped rump angle and ideal TL makes LUCRATIVE a high demand sire from this proven results cow family.
            <br>LUCRATIVE is housed in the USA and qualifies for all international export markets.
          </p>
          <p>
            Proven Performance Sire highlights:
          </p>
          <p>
            <b>734HO00111 BLASKA</b> (Doc x Denver x Bombero) BLASKA is the No. 1 sire in the breed among all sires over +2.93 PTAT and over +2400 Milk. BLASKA is the highest PTAT daughter proven sire for Jetstream with +2.93 PTAT and +2.37 UDC along with +2424 Milk, a great linear profile adding strength, width, depth and open dairy frames along with positive teat length. BLASKA comes from a who’s-who of breeder, including Coldsprings, Morsan, Rocky Mountain and Rosy-Lane – these outstanding breeders have all had lasting impact on the pedigree of BLASKA. BLASKA is a King Doc x GP83 Denver x GP84 Bombero x GP82 McCuthchen x VG85 2* Planet x VG 1* Ramos x O-Man x Lynch x Goldfinger. BLASKA semen is readily available. He is housed in Canada and qualifies for all international export markets.
            <br>BLASKA daughters are uniform cows, wide, deep framed strong cows with capacity. Locomotion and FLC are superior. Standout udders with greate texture, deep ligament, strong attachments and perfect TL and placement. A large herd, Robotic dairymen’s dream is BALASKA.
          </p>
          <p class="my-3">For more information, visit <a routerLink="/">www.jetstreamgenetics.com</a> or call
            <span>1.855.4JETSTREAM (855-453-8787)</span>.
          </p>
        </div>
      </main>
    </div>
</div>
