import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.termsAndConditions);
  }

}
