import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../utils/constants';

@Component({
  selector: 'app-news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.scss']
})
export class NewsAndEventsComponent implements OnInit {

  constructor(private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.newsAndEvents);
  }

}
