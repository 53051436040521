import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Catalog } from '../models/catalog.model';
import { CATALOG_COVER_BACK, CATALOG_COVER_FOLDER, CATALOG_COVER_LIST, CATALOG_COVER_LIST_THUMB, CATALOG_ORDER, DAYS, MONTHS, catalogItemsKey } from '../utils/constants';
import { DatePipe } from '@angular/common';
import { SortByPipe } from '../pipes/sort-by.pipe';
import { BullSearchService } from '../services/bull-search.service';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Router } from '@angular/router';
import { Product } from '../models/product.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  @ViewChild('pdf', { static: false }) pdf: PDFExportComponent;
  product: Product;
  public showTest: boolean = false;

  public contentListPageSize = 30;
  public nextMessage = 'NEXT';
  public title = '';
  public catalog: Catalog = new Catalog();
  public generatingCatalog: boolean = false;

  public coverFolder: string = CATALOG_COVER_FOLDER;
  public coverListThumb: any[] = CATALOG_COVER_LIST_THUMB;
  public coverList: any[] = CATALOG_COVER_LIST;

  public data: string[] = [];
  public stepsList: number[] = [];
  public stepsIndex: number = 0;
  public stepsTitle: string[] = ['', 'CONTACT', 'CREATE_CATALOG'];
  public items_bulls_dairy: any[] = [];
  public orderList: any[] = CATALOG_ORDER;
  public statusMessage: string = '';
  public geneticSummary: any;
  public checkGeneticSummary: boolean = true;

  public languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
  ];

  selectedLanguage: string = 'en';

  constructor(
    public datepipe: DatePipe,
    public sortBy: SortByPipe,
    public bsService: BullSearchService,
    private _router: Router,
    private translate: TranslateService,
  ) {
    this.selectedLanguage = localStorage.getItem('selectedLanguage') || this.selectedLanguage;
    translate.setDefaultLang(this.selectedLanguage);
    this.translate.use(this.selectedLanguage);
    this.catalog.coverId = this.selectedLanguage === 'es' ? 2 : 1;
  }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem(catalogItemsKey));
    if (!this.data){
      this.goBackList()
    }
    this.configureSteps();

    // this.catalog.address = "rua xoxoxoxo";
    // this.catalog.name = "Andre Luiz";
    // this.catalog.title = "Teste titulo";
    // this.catalog.phone = "+5534999297237";
    // this.catalog.email = "andre.nunes@urus.org";

  }



  configureSteps(){
    this.stepsList = [];

    this.stepsList.push(0); // select options
    if (this.catalog.covers) { this.stepsList.push(1); } // config
    this.stepsList.push(2); // create catalog
  }

  goBack(){
    this.configureSteps();
    this.nextMessage = 'NEXT';
    this.stepsIndex = this.stepsIndex - 1;
  }

  loadItems(){
    this.items_bulls_dairy = [];
    var bullIds = '';
    this.data.forEach(e => {
       this.bsService.getBull(e, true).subscribe(resp => {
         this.items_bulls_dairy.push(resp);
         bullIds = this.items_bulls_dairy.map(item => item.BullID).join(',');
         if (this.data.length === this.items_bulls_dairy.length){
            var obj = {
              "lang": this.selectedLanguage === 'en' ? 'en-us' : 'es-es',
              "bulls": bullIds,
            }
            this.bsService.getGeneticSummary(obj).subscribe(resp => {
              this.geneticSummary = resp;
              this.goPrint();
            });
         }
       });
    });
  }

  goNext(){
    this.configureSteps();
    let currentStep = this.stepsList[this.stepsIndex];
    let stepCreate = 2;
    let plus = this.catalog.covers ? 1 : 2;

    if ((currentStep + plus) < stepCreate){
      this.nextMessage = 'NEXT';
      this.stepsIndex = this.stepsIndex + 1;
    }
    else{
      if ((currentStep + plus) === stepCreate){
        this.nextMessage = 'CREATE';
        this.stepsIndex = this.stepsIndex + 1;
      }
      else{
        this.statusMessage = 'PREPARING_THE_CATALOG_PLEASE_WAIT';
        this.generatingCatalog = true;
        this.loadItems();
      }
    }

  }

  goPrint(){
    this.sortItems();

    setTimeout(()=>{
      this.pdf.saveAs('Jetstream-Catalog.pdf');
      this.showDownloadMessage();
    }, 500);
  }

  showDownloadMessage(){
    let time = this.items_bulls_dairy.length * 300;
    this.statusMessage = 'DOWNLOADING';
    setTimeout(()=>{
      this.statusMessage = '';
      this.generatingCatalog = false;
    }, time);
  }

  sortItems(){
    this.catalog.items = [];
    let list = this.items_bulls_dairy;
    this.items_bulls_dairy = [];

    if (list.length > 0)
    {
      let sort = this.getOrderField();
      let page = 0;
      let items = 0;
      let pages = 0;
      page = this.catalog.covers ? page + 1 : page;
      if (this.catalog.tableContents)
      {
        items = list.length;
        pages = Math.ceil(items / this.contentListPageSize);
        page = page + pages;
      }

      this.sortBy.transform(list, 'asc', sort).forEach(e => {
        page = page + 1;
        this.catalog.items.push({ description: `${e.SemenCode} - ${e.ShortName}`, page: page});
        this.items_bulls_dairy.push(e);
      });

    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // getDate(){
  //   let current = new Date();
  //   let latest_date = `${DAYS[current.getDay()]}, ${MONTHS[current.getMonth()]} ${this.datepipe.transform(current, 'dd')}, ${current.getFullYear()}`;
  //   return latest_date;
  // }

  getDate() {
    const current = new Date();
    const day = this.translate.instant(`${DAYS[current.getDay()]}`.toUpperCase());
    const month = this.translate.instant(`${MONTHS[current.getMonth()]}`.toUpperCase());
    const dayNumber = this.datepipe.transform(current, 'dd');
    const year = current.getFullYear();
    var latest_date;

    if (this.selectedLanguage === 'es') {
      latest_date = `${day}, ${dayNumber} de ${month}, ${year}`;
    } else {
      latest_date = `${day}, ${month} ${dayNumber}, ${year}`;
    }

    return latest_date;
  }

  getBarWidthPrint(value: number) {
    let width = 0;
    if (value > 2 || value < -2) {
      width = 50;
    } else {
      let perc = Math.abs(value) / 2 * 100;
      width = 50 * (perc / 100);
    }
    let val = `${width}px`;
    return val;
  }

  getOrderField(){
    let sort: string = '';
    this.orderList.forEach(e => {
      if (e.id === this.catalog.order)
      {
        sort = e.field;
        return e.field;
      }
    });
    return sort;
  }

  getStepTitle(){
    return this.stepsTitle[this.stepsList[this.stepsIndex]];
  }

  getCoverByID(id: number, thumb: boolean): string{
    if (thumb){
      let img = `${this.coverFolder}${this.coverListThumb[0].image}`;
      this.coverListThumb.forEach(e => {
        if (e.id == id)
        {
          img = `${this.coverFolder}${e.image}`;
        }
      });
      return img;
    }
    else{
      let img = `${this.coverFolder}${this.coverList[0].image}`;
      this.coverList.forEach(e => {
        if (e.id == id)
        {
          img = `${this.coverFolder}${e.image}`;
        }
      });
      return img;
    }
  }

  getBackCover(){
    return `${CATALOG_COVER_FOLDER}${CATALOG_COVER_BACK}`;
  }
  goBackList(){
    this._router.navigateByUrl('/purchase-our-sires');
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.selectedLanguage = language;
    localStorage.setItem('selectedLanguage', this.selectedLanguage);
    this.catalog.coverId = this.selectedLanguage === 'es' ? 2 : 1;
  }

  getBarWidth(value: number) {
    let width = 0;
    if (value > 2 || value < -2) {
      width = 125;
    } else {
      width = Math.abs(value) / 2 * 100;
    }
    return `${width}px`;
  }
}
