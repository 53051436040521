export class Catalog {
    tableContents!: boolean;
    onlyBulls!: boolean;
    config!: boolean;
    covers!: boolean;

    order!: number;
    coverId!: number;
    title!: string;
    name!: string;
    address!: string;
    phone!: string;
    email!: string;
    items!: CatalogTable[];
  
    constructor() {
      this.tableContents = true;
      this.onlyBulls = false;
      this.config = true;
      this.covers = true;

      this.order = 1;
      this.coverId = 1;
      this.title = '';
      this.name = '';
      this.address = '';
      this.email = '';
      this.items = [];
    }
  
  }
  
  export class CatalogTable{
    constructor(
      public description: string,
      public page: number,
    ){}
  }
  
  