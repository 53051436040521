import { Injectable } from '@angular/core';
import { ShoppingCartItem } from '../models/shopping-cart-item.model';
import { ShoppingCart } from '../models/shopping-cart.model';;
import { shoppingCartKey, currentCoupon } from '../utils/constants'

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private _cart: ShoppingCart;

  constructor() {
    if (localStorage.getItem(shoppingCartKey)) {
      this._cart = this.getCart();
      if (!this._cart.items || !this._cart.items.length) {
        this.resetCart();
      }
      if(this._cart.coupon && !this.couponIsValid(this._cart.coupon)) {
        this.removeCoupon();
      }

    } else {
      this._cart = new ShoppingCart();
      this.saveCart();
    }
  }

  private getCart(): ShoppingCart {
    return JSON.parse(localStorage.getItem(shoppingCartKey));
  }

  getItems(): ShoppingCartItem[] {
    return this._cart.items;
  }

  addItem(item: ShoppingCartItem) {
    const existingItem = this._cart.items.find(sci => sci.semenCode === item.semenCode);
    if (existingItem) {
      existingItem.quantity += item.quantity;
    } else {
      this._cart.items.push(item);
    }
    this.saveCart();
  }


  updateItem(item: ShoppingCartItem) {
    const existingItem = this._cart.items.find(sci => sci.semenCode === item.semenCode);
    if (existingItem) {
      existingItem.quantity = item.quantity;
    }
    this.saveCart();
  }

  removeItem(semenCode: string) {
    this._cart.items.splice(this._cart.items.findIndex(sci => sci.semenCode === semenCode), 1);
    if (!this._cart.items.length) {
      this.resetCart();
    } else {
      this.saveCart();
    }
  }

  getItemsQuantity() {
    return this._cart && this._cart.items ? this._cart.items.length : 0;
  }

  getItemsTotal() {
    return this._cart.items.reduce((a, b) => a + (b.quantity * b.price || 0), 0);
  }

  getGrandTotal() {
    return this.getItemsTotal() - this.getDiscount() + this.getShippingTax();
  }

  getShippingTax() {
    return this._cart.shippingTax;
  }

  getDiscount(): number {
    // if (this.couponIsValid(this._cart.coupon)) {
    //   const units = this.getUnits();      
    //   if (units >= 250) {
    //     return this.getItemsTotal() * 0.4;
    //   }

    //   if (units >= 100) {
    //     return this.getItemsTotal() * 0.3;
    //   }

    //   return 0;
    // }
    // return 0;
    const perc = this.getDiscountPercent()/100;
    return this.getItemsTotal() * perc;
  }

  getDiscountPercent(): number {
    if (this.couponIsValid(this._cart.coupon)) {
      const units = this.getUnits();
      if (units >= 60) {
        return 35;
      }
      if (units >= 20) {
        return 30;
      }
      return 0;
    }
    return 0;
  }

  getCoupon() {
    return this._cart.coupon;
  }

  setShippingTax(value: number) {
    this._cart.shippingTax = value;
    this.saveCart();
  }

  resetCart() {
    localStorage.removeItem(shoppingCartKey);
    this._cart = new ShoppingCart();
    this.saveCart();
  }

  applyCoupon(value: string): boolean {
    if (this.couponIsValid(value)) {
      this._cart.coupon = value;
      this.saveCart();
      return true;
    } else {
      this.removeCoupon();
      return false;
    }
  }

  removeCoupon() {
    this._cart.coupon = '';
    this.saveCart();
  }

  getUnits(): number {
    if (!this._cart || !this._cart.items || !this._cart.items.length) {
      return 0;
    }
    return this._cart.items.reduce((a, b) => a + b.quantity, 0);
  }

  private couponIsValid(value: string): boolean {
    const today = new Date();
    return value === currentCoupon.code && today <= currentCoupon.expiration
  }

  private saveCart() {
    localStorage.setItem(shoppingCartKey, JSON.stringify(this._cart));
  }

  cartIsValid(): boolean {
    const items = this.getItems();
    return !items.some(item => item.quantity % 10 !== 0);
  }
}
