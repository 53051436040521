import { ColumnDefinition } from "../models/column-definition.model";
import { Coupon } from "../models/coupon.model";

export const defaultColumnsDefinitions: ColumnDefinition[] = [
    { definition: 'Select', header: 'Select', stick: true, show: true },
    { definition: 'SemenCode', header: 'NAAB Code', stick: true, show: true },
    { definition: 'ShortName', header: 'Name', stick: true, show: true },
    { definition: 'Price', header: 'Price', stick: true, show: true },
    { definition: 'TPI', header: 'GTPI', stick: false, show: true },
    { definition: 'NM', header: 'NM$', stick: false, show: true },
    { definition: 'Milk', header: 'Milk', stick: false, show: true },
    { definition: 'Fat', header: 'Fat', stick: false, show: true },
    { definition: 'FatPerc', header: 'Fat %', stick: false, show: true },
    { definition: 'Prot', header: 'Protein', stick: false, show: true },
    { definition: 'ProtPerc', header: 'Pro %', stick: false, show: true },
    { definition: 'PTAT', header: 'Type', stick: false, show: true },
    { definition: 'UDC', header: 'UDC', stick: false, show: true },
    { definition: 'FLC', header: 'FLC', stick: false, show: true },
    { definition: 'PL', header: 'PL', stick: false, show: true },
    { definition: 'DPR', header: 'DPR', stick: false, show: true },
    { definition: 'SCE', header: 'SCE', stick: false, show: true },
    { definition: 'BetaCasein', header: 'BCN', stick: false, show: true },
    { definition: 'CM', header: 'CM$', stick: false, show: false },
    { definition: 'CFP', header: 'CFP', stick: false, show: false },
    { definition: 'Prel', header: 'Prel', stick: false, show: false },
    { definition: 'SCS', header: 'SCS', stick: false, show: false },
    { definition: 'DCE', header: 'DCE', stick: false, show: false },
    { definition: 'SSB', header: 'SSB', stick: false, show: false },
    { definition: 'DSB', header: 'DSB', stick: false, show: false },
    { definition: 'LIV', header: 'LIV', stick: false, show: false },
    { definition: 'TRel', header: 'TRel', stick: false, show: false },
    { definition: 'aAa', header: 'aAa', stick: false, show: false },
    { definition: 'DMS', header: 'DMS', stick: false, show: false },
    { definition: 'Pedigree', header: 'Pedigree', stick: false, show: false },
    { definition: 'CCR', header: 'CCR', stick: false, show: false },
    { definition: 'HCR', header: 'HCR', stick: false, show: false },
];

export const freeShippingTax = 0;
export const twoDaysShippingTax = 120;
export const threeDaysShippingTax = 75;

export const shoppingCartKey = 'shopping-cart';
export const catalogItemsKey = 'catalog-items';

export const currentCoupon: Coupon = {
    code: 'jetdfa2020',
    expiration: new Date(2021, 11, 31, 23, 59, 59)
}

export const messages = {
    orderSuccess: 'Your order request has been submitted!',
    error: 'An error has occurred!',
    tryAgain: 'Try again'
}

export const pageTitles = {
    home: 'Jetstream Genetics',
    purchaseOurSires: 'Jetstream - Purchase our sires',
    contactUs: 'Jetstream - Contact us',
    aboutUs: 'Jetstream - About us',
    newsAndEvents: 'Jetstream - News and Events',
    privacyPolicy: 'Jetstream - Privacy Policy',
    disclaimer: 'Jetstream - Disclaimer',
    termsAndConditions: 'Jetstream - Terms and Conditions',
    notFound: 'Jetstream - Page Not Found'
}



export const DAYS = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
export const MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];


export const CATALOG_COVER_FOLDER: string = '/assets/images/catalog/covers/';
export const CATALOG_COVER_LIST: any[] = [
  { id: 1, image: 'cover_1.jpg' },
  { id: 2, image: 'cover_1_es.jpg' }
]
export const CATALOG_COVER_LIST_THUMB: any[] = [
  { id: 1, image: 'cover_1_thumb.jpg' },
  { id: 2, image: 'cover_1_es_thumb.jpg' }
]
export const CATALOG_COVER_BACK: string = 'cover_back.jpg';

export const CATALOG_ORDER: any[] = [
  { id: 1, desc: 'SemenCode', field:'SemenCode'},
  { id: 2, desc: 'Shortname', field:'ShortName'}
]
