<div class="container py-3 institutional-page">
    <div class="row">
        <div class="col">
            <h1>About Jetstream Genetics</h1>
        </div>
    </div>
    <main class="row my-3">
        <div class="col-12 col-md-6 pr-md-5">
            <h2 class="text-uppercase">Philosophy</h2>
            <p>
                Jetstream Genetics seeks to provide breed-defining sires through the upper echelon of genomics, whether those genetics are from deep-pedigreed cow families or high numbers, extreme type or extreme production, Red or Polled, or the very best bulls that combine these attributes. We will always seek to deliver excellence to our customers, continually giving them the tools they need to increase their rate of genetic gain. In a rapidly changing and evolving AI marketplace, we continue to secure the most sought-after genetics by offering competitive and favorable contracts for our customers to offer them their greatest return, treating clients with care and honesty, and sharing our passion for the dairy industry. Jetstream will use its significant resources to seek out elite genetics for our clients’ benefit, so that we may be a catalyst in moving genetics forward.
            </p>
        </div>
        <div class="col-12 col-md-6 pl-md-5">
            <h2 class="text-uppercase">Our mission</h2>
            <p>
                Our Mission at Jetstream Genetics LLC is to be the leading supplier of cutting edge global genetics. We plan on being the international source of the Holstein Breed’s greatest sires. The people behind Jetstream Genetics have developed some of the premier cow families in the world today. We have the passion to deliver excellence to your breeding program in all corners of the world. When only the best will do, don’t compromise.
            </p>
            <p class="highlight">To all of you, from all of us at Jetstream – Thank you and Happy eCommerce!</p>
            <div class="d-flex flex-column align-items-center">
                <img class="my-3" src="/assets/images/roger-turner.png" alt="Roger Turner">
                <h2>Roger Turner</h2>
                <p>Global Sales and Genetic Manager</p>
            </div>
        </div>
    </main>
</div>