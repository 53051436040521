import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';

import { PurchaseOurSiresComponent } from './purchase-our-sires/purchase-our-sires.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './inteceptors/loader.interceptor';
import { TraitsDialogComponent } from './traits-dialog/traits-dialog.component';
import { NoStickyPipe } from './pipes/no-sticky.pipe';
import { ProductComponent } from './product/product.component';
import { EvaluationDatePipe } from './pipes/evaluation-date.pipe';
import { PositiveSignPipe } from './pipes/positive-sign.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { ShoppingCartButtonComponent } from './shopping-cart-button/shopping-cart-button.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ZeroToDashPipe } from './pipes/zero-to-dash.pipe';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FeaturedBullComponent } from './featured-bull/featured-bull.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DialogAlertComponent } from './dialog-alert/dialog-alert.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogDairy1pComponent } from './catalog/catalog-dairy1p/catalog-dairy1p.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PurchaseOurSiresComponent,
    LoaderComponent,
    TraitsDialogComponent,
    NoStickyPipe,
    ProductComponent,
    EvaluationDatePipe,
    PositiveSignPipe,
    SortByPipe,
    ShoppingCartButtonComponent,
    ShoppingCartComponent,
    NotFoundComponent,
    CheckoutComponent,
    CustomCurrencyPipe,
    ZeroToDashPipe,
    AboutUsComponent,
    ContactUsComponent,
    NewsAndEventsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    DisclaimerComponent,
    HomeComponent,
    FooterComponent,
    FeaturedBullComponent,
    DialogAlertComponent,
    CatalogComponent,
    CatalogDairy1pComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatCardModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSelectModule,
    PDFExportModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en'
    },
    { provide: LOCALE_ID, useValue: environment.culture},
    CurrencyPipe,
    DatePipe,
    SortByPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
