import { Component, OnInit, ViewChild } from '@angular/core';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { ShoppingCartItem } from '../models/shopping-cart-item.model';
import { NgForm } from '@angular/forms';
import { freeShippingTax, threeDaysShippingTax, twoDaysShippingTax } from '../utils/constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  shippingTax: number = threeDaysShippingTax;
  coupon: string = '';
  couponError: string = '';
  items: ShoppingCartItem[] = [];

  @ViewChild('form', { static: false }) form: NgForm;

  constructor(public scService: ShoppingCartService, private _router: Router) { }

  ngOnInit(): void {
    this.items = this.scService.getItems();
    this.coupon = this.scService.getCoupon();
    this.updateShippingTax();
    if (this.items.length) {
      setTimeout(() => {
        this.validateForm();
      }, 100);
    }
  }

  itemChange(index: number) {
    // if (this.validateItem(index)) {
    this.validateItem(index);
    this.scService.updateItem(this.items[index]);
    this.updateShippingTax();
    // }
  }

  validateItem(index: number) {
    this.form.controls[`txtQty_${index}`].setErrors(null);
    if (!this.items[index].quantity || this.items[index].quantity < 10) {
      this.form.controls[`txtQty_${index}`].setErrors({ invalid: true });
      return false;
    }
    else {
      try {
        if (this.items[index].quantity % 10 !== 0) {
          this.form.controls[`txtQty_${index}`].setErrors({ invalid: true });
          return false;
        }
      }
      catch {
        this.form.controls[`txtQty_${index}`].setErrors({ invalid: true });
        return false;
      }
    }
    return true;
  }

  removeItem(semenCode: string) {
    this.scService.removeItem(semenCode);
    this.items = this.scService.getItems();
    this.updateShippingTax();
  }

  updateShippingTax() {
    if (this.scService.getShippingTax() !== twoDaysShippingTax) {
      const units = this.scService.getUnits();
      this.scService.setShippingTax(this.scService.getItemsTotal() >= 1000 || (this.coupon && units >= 60) ? freeShippingTax : threeDaysShippingTax);
    }
    this.shippingTax = this.scService.getShippingTax();
  }

  shippingTaxChange() {
    this.scService.setShippingTax(this.shippingTax);
  }

  applyCoupon(value: string) {
    if (this.scService.applyCoupon(value)) {
      this.couponError = '';
      this.coupon = value;
      this.updateShippingTax();
    } else {
      this.couponError = `Coupon code "${value}" is not valid.`
    }
  }

  removeCoupon() {
    this.scService.removeCoupon();
    this.coupon = '';
    this.updateShippingTax();
  }

  validateForm() {
    Object.keys(this.form.controls).forEach((field, index) => {
      this.validateItem(index);
      this.form.controls[field].markAsTouched();
    });
    return this.form.valid;
  }

  goToCheckout() {
    if (this.validateForm()) {
      this._router.navigateByUrl('/purchase-our-sires/checkout')
    }
  }

}
