<main class="container py-3">
    <div class="row">
        <div class="col">
            <h1>Shopping Cart</h1>
        </div>
    </div>
    <div class="row" *ngIf="!items || !items.length; else cartItems">
        <div class="col d-flex flex-column align-items-center py-5 empty-cart">
            <mat-icon class="mt-5">shopping_cart</mat-icon>
            <h2>Shopping Cart is Empty</h2>
            <p class="mb-0">You have no items in your shopping cart.</p>
            <p>Click <a class="link" routerLink="/purchase-our-sires">here</a> to continue shopping.</p>
        </div>
    </div>
    <ng-template #cartItems>
        <div class="row my-3">
            <div class="col-12 col-md-7">                
                <form #form="ngForm">
                    <mat-card *ngFor="let item of items; let i = index;" class="mb-3 d-flex">
                        <div class="flex-grow-1">
                            <mat-card-header>
                                <mat-card-title>{{item.fullName}}</mat-card-title>
                                <mat-card-subtitle>{{item.semenCode}} - {{item.intRegNum}}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <label for="txtQty" class="mr-2">Quantity:</label>
                                <input class="text-right mr-3" [class.error]="form.controls['txtQty_' + i]?.invalid"
                                    id="txtQty_{{i}}" name="txtQty_{{i}}" type="number" step="10" min="10"
                                    [(ngModel)]="item.quantity" (input)="itemChange(i)">
                                <label>Unit Price: <span
                                        class="font-weight-bold">{{item.price | currency}}</span></label>
                                <small class="d-block" [class.error]="form.controls['txtQty_' + i]?.invalid">
                                    *This product is available for purchase in increments of 10
                                </small>
                            </mat-card-content>
                        </div>
                        <div class="flex-grow-1 d-flex flex-column justify-content-center">
                            <div>
                                <p class="mb-0">Total</p>
                                <h3>{{(item.price * item.quantity) | currency}}</h3>
                            </div>
                        </div>
                        <button type="button" mat-mini-fab color="warn" (click)="removeItem(item.semenCode)">
                            <mat-icon>remove_shopping_cart</mat-icon>
                        </button>
                    </mat-card>
                </form>
            </div>
            <div class="col-12 col-md-5">
                <mat-card class="cart-summary">
                    <mat-card-content>
                        <div class="d-flex mb-2">
                            <span class="flex-grow-1">Total ({{items.length}}
                                item{{items.length > 1 ? 's' : ''}})</span>
                            <span class="font-weight-bold">{{scService.getItemsTotal() | currency}}</span>
                        </div>
                        <div class="d-flex mb-2" *ngIf="scService.getDiscount()">
                            <span class="flex-grow-1">Discount ({{scService.getDiscountPercent() + '%'}})</span>
                            <span class="font-weight-bold">({{scService.getDiscount() | currency}})</span>
                        </div>
                        <div class="d-flex mb-2">
                            <span class="flex-grow-1">Shipping Cost</span>
                            <span class="font-weight-bold">{{scService.getShippingTax() | currency}}</span>
                        </div>                        
                        <div class="d-flex mb-2">
                            <span class="flex-grow-1">Grand Total</span>
                            <span class="font-weight-bold grand-total">{{scService.getGrandTotal() | currency}}</span>
                        </div>
                        <div class="coupon-code mb-3" [class.valid]="coupon">
                            <h3>Coupon Code</h3>                            
                            <ng-container *ngIf="!coupon; else hasCoupon">
                                <label class="d-block">Enter your coupon code if you have one</label>
                                <div class="d-flex">
                                    <input type="text" class="flex-grow-1 mr-2" name="txtCoupon" #fcCoupon>
                                    <button type="button" mat-button (click)="applyCoupon(fcCoupon.value)">Apply</button>
                                </div>
                                <small class="mat-error" *ngIf="couponError">{{couponError}}</small>
                            </ng-container>
                            <ng-template #hasCoupon>
                                <div class="d-flex">
                                    <input type="text" class="flex-grow-1 mr-2" readonly [(ngModel)]="coupon">
                                    <button type="button" mat-button color="warn" (click)="removeCoupon()">Remove</button>
                                </div>
                            </ng-template>
                        </div>
                        <div class="shipping-tax d-flex flex-column mb-3">
                            <h3 class="mb-0">Estimate Shipping and Tax</h3>
                            <p class="green">Free shipping for orders over $1,000</p>
                            <mat-radio-group class="d-flex flex-column" [(ngModel)]="shippingTax"
                                (change)="shippingTaxChange()">
                                <mat-radio-button [disabled]="scService.getItemsTotal() < 1000 && (coupon && scService.getUnits() < 60)" [value]="0">
                                    Free 3 days shipping
                                </mat-radio-button>
                                <mat-radio-button [value]="120">
                                    2 days shipping $120.00
                                </mat-radio-button>
                                <mat-radio-button [disabled]="(coupon !== '' && scService.getUnits() >= 60) || scService.getItemsTotal() >= 1000" [value]="75">
                                    3 days shipping $75.00
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button mat-flat-button class="text-uppercase py-2" color="accent"
                                [disabled]="form.invalid" (click)="goToCheckout()">
                                Proceed to Checkout
                        </button>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div class="d-flex justify-content-center my-4">
                    <a routerLink="/purchase-our-sires" class="text-uppercase link">Continue Shopping</a>
                </div>
            </div>
        </div>
    </ng-template>
</main>