import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BullListItem } from '../models/bull-list-item.model';

@Component({
  selector: 'app-featured-bull',
  templateUrl: './featured-bull.component.html',
  styleUrls: ['./featured-bull.component.scss']
})
export class FeaturedBullComponent implements OnInit {
  
  bsUrl = environment.bsUrl;
  @Input() product: BullListItem;

  constructor() { }

  ngOnInit(): void {
  }

}
