import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get("catalog") ) {
            return next.handle(request);
        }
        else{            
            this.loaderService.show(); 
            return next.handle(request).pipe(finalize(() => this.loaderService.hide()));
        }
    }
    constructor(private loaderService: LoaderService) { }
}