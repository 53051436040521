import { PercentPipe } from '@angular/common';

export class OrderDto {
    discount: number;
    discountPercent: number;
    shippingTax: number;
    grandTotal: number;
    items: OrderItem[];
    customer: CustomerInformation;
    constructor() {
        this.items = [];
    }
}

export class CustomerInformation {
    constructor(public name: string, public farmName: string, public streetAddress: string,
        public city: string, public state: string, public country: string, public zipCode: string, public email : string,
        public phone: string,
        public comments: string,){}    
}

export class OrderItem {
    constructor(public name: string, public semenCode: string, public intRegNum: string,
        public quantity: number, public subtotal: number){}
}