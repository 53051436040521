<footer class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-auto d-flex justify-content-center justify-content-md-start">
                <a routerLink="/">
                    <img class="img-fluid logo" src="/assets/images/jetstream-logo-white.png" />
                </a>
            </div>
            <div class="col-4 col-md-auto">
                <nav class="my-4 my-md-0 mx-0 mx-md-5">
                    <mat-nav-list>
                        <a mat-list-item routerLink="/about-us">About Us</a>
                        <a mat-list-item routerLink="/contact-us">Contact Us</a>
                        <a mat-list-item routerLink="/privacy-policy">Privacy Policy</a>
                        <a mat-list-item routerLink="/terms-and-conditions-of-sale">Terms and Conditions</a>
                        <a mat-list-item routerLink="/disclaimer">Disclaimer</a>
                    </mat-nav-list>
                </nav>
            </div>
            <div class="col d-none">
                <div class="newsletter">
                    <h4>Subscribe to newsletter</h4>
                    <p>Sign up to receive weekly updates from Jetstream!</p>
                    <div class="d-flex">
                        <input type="text" class="flex-grow-1" placeholder="Your Email">
                        <button mat-flat-button class="blue-button">Subscribe</button>
                    </div>
                </div>
            </div>
            <div class="col-4 col-md-auto">
              <Address class="my-4 my-md-0 mx-0 mx-md-5">
                <p>Address</p>
                <p>N8452 Airport Rd,<br>
                P.O. Box 437<br>
                Watertown, WI 53094</p>
              </Address>
            </div>
            <div class="col-4 col-md-auto">
              <Address class="my-4 my-md-0 mx-0 mx-md-5">
                <p>General Inquires <br>
                  please contact <br>
                </p>
                <p>
                  <a class="text-white fs-md" href="mailto:info@jetstreamgenetics.com">
                    <span class="d-none d-sm-block">info@jetstreamgenetics.com</span> <br>
                    <span class="d-sm-none">Redirect e-mail</span>
                  </a>
                </p>
              </Address>
            </div>
        </div>

    </div>
</footer>
