import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evaluationDate'
})
export class EvaluationDatePipe implements PipeTransform {

  transform(value: string): string {
    if(!value) {
      return '';
    }
    const strSplitted = value.split('-');

    if(strSplitted.length < 2) {
      return '';
    }

    const yearMonth = strSplitted[1];

    if(yearMonth.length < 6) {
      return '';
    }

    return `${yearMonth.substr(4,2)}/${yearMonth.substr(0,4)}`;
  }

}
