<main class="py-0 m-2" *ngIf="product">
    <div class="my-0 row header">
        <div class="col-6 img-pdf">
          <img class="mat-elevation-z4" width="290" src="{{bsUrl}}{{product.BullImage}}">
        </div>
        <div class="col-6 p-0 pl-3">
          <span class="m-0 bullName">{{product.ShortName}}</span>
          <h3 class="mb-0">{{product.SemenCode}}</h3>
          <h3 class="mb-0">{{product.FullName}}</h3>
          <h3 class="mb-0 font-weight-normal">{{product.Pedigree}}</h3>
          <h3>{{product.IntRegNum}} | DOB {{product.BirthDate | date: 'MM/dd/yyyy'}}</h3>
          <p>
              <span class="font-weight-bold mr-1">{{'KAPPA_CASEIN' | translate}} </span>{{product.KappaCasein}} |
              <span class="font-weight-bold ml-1 mr-1">{{'BETA_CASEIN' | translate}} </span>{{product.BetaCasein}}
          </p>
          <p>
            <span class="font-weight-bold">{{'HAPLOTYPES' | translate}} </span>{{product.Haplotypes}}</p>
          <p>
            <span class="font-weight-bold mr-1">{{'GENETIC_CODES' | translate}} </span>{{product.GeneticCodes}}</p>
          <p>
              <span class="font-weight-bold">aAa </span>{{product.aAa}} |
              <span class="font-weight-bold">DMS </span>{{product.DMS}} |
              <span class="font-weight-bold">EFI </span>{{product.EFI}}%
          </p>

          <p><span class="font-weight-bold">{{'CURRENT_PROOF' | translate}} </span>{{product.CurrentProof}}</p>
          <p><span class="font-weight-bold">{{'DATA' | translate}} </span>{{'POWERED_BY' | translate}} CDCB | HA-USA</p>
        </div>
    </div>
    <div class="my-2 row">
        <div class="col-6 pr-0">
            <div class="block">
                <div class="block-header">
                    <span class="title">{{'CONFORMATION' | translate}}</span>
                    <span>{{'BASED_ON' | translate}}
                        <span class="font-weight-bold">{{conformationDaughters | zeroToDash}}</span>
                        {{'DAUGHTERS_IN' | translate}}
                        <span class="font-weight-bold">{{conformationHerds | zeroToDash}}</span>
                        {{'HERDS' | translate}} ({{product.ConfRel}}% Rel)
                    </span>
                </div>
                <table class="w-100 chart">
                    <tr>
                        <td>{{'PTAT' | translate}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.PTAT >= 0, 'negative': product.PTAT < 0}"
                                    [style.width]="getBarWidth(product.PTAT)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.PTAT}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{'UDC' | translate}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.UDC >= 0, 'negative': product.UDC < 0}"
                                    [style.width]="getBarWidth(product.UDC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.UDC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{'FLC' | translate}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.FLC >= 0, 'negative': product.FLC < 0}"
                                    [style.width]="getBarWidth(product.FLC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.FLC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let c of product.Conformations | sortBy:'asc':'Order'">
                        <td>{{c.Trait | uppercase | translate}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': c.Value >= 0, 'negative': c.Value < 0}"
                                    [style.width]="getBarWidth(c.Value)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{c.Value | number:'1.2-2' | positiveSign }}</td>
                        <td class="text-nowrap">{{ (c.Value >= 0 ? c.Pos : c.Neg) | uppercase | translate }}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-2" *ngIf="product.PedigreeList && product.PedigreeList.length">
                <div class="block-header">
                    <span class="title">{{'PEDIGREE' | translate}}</span>
                </div>
                <table class="w-100">
                    <tr *ngFor="let p of product.PedigreeList | sortBy:'asc':'Order'">
                        <td class="type">{{p.Type | translate}}</td>
                        <td>{{p.Name}}</td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="col-6">
            <div class="tpi-nm-container">
                <div>
                    <span>TPI</span>
                    <span>{{product.TPI}}</span>
                </div>
                <div>
                    <span>NM$</span>
                    <span>{{product.NM}}</span>
                </div>
            </div>
            <div class="block my-2">
                <div class="block-header">
                    <span class="title">{{'PRODUCTION' | translate}}</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">{{'MILK' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Milk | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.MilkRel}}%</span>
                            Rel
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">{{'PROTEIN' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Prot | positiveSign}}
                            </span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">{{'FAT' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.Fat | positiveSign}}</span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-2">
                <div class="block-header gray">
                    <span>{{'BASED_ON' | translate}}
                        <span class="font-weight-bold">{{productionDaughters | zeroToDash}}</span>
                        {{'DAUGHTERS_IN' | translate}}
                        <span class="font-weight-bold">{{productionHerds | zeroToDash}}</span>
                        {{'HERDS' | translate}}
                        <span class="font-weight-bold">({{product.USDaughters}}% {{'US_DAUGHTERS' | translate}})</span>
                    </span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">{{'MILK_PRODUCTION_US_DAUGHTERS' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.MilkUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right"><span class="font-weight-bold">{{product.MilkUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">{{'FAT_YIELD_US_DAUGHTERS' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.FatUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">{{'PROTEIN_YIELD_US_DAUGHTERS' | translate}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.ProtUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-2">
                <div class="block-header">
                    <span class="title">{{'HEALTH_TRAITS' | translate}}</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="htTitleLeft">{{'PRODUCTIVE_LIFE' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.PL | positiveSign}}</td>
                        <td class="htTitleRight">{{'DAUGHTER_PREGNANCY_RATE' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.DPR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="htTitleLeft">{{'LIVABILITY' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.LIV | positiveSign}}</td>
                        <td class="htTitleRight">{{'COW_CONCEPTION_RATE' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.CCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="htTitleLeft">{{'SOMATIC_CELL_SCORE' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.SCS | positiveSign}}</td>
                        <td class="htTitleRight">{{'HEIFER_CONCEPTION_RATE' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.HCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="htTitleLeft">{{'MASTITIS' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.Mast | positiveSign}}</td>
                        <td class="htTitleRight">{{'Metritis' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.MET | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="htTitleLeft">{{'KETOSIS' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.KET | positiveSign}}</td>
                        <td class="htTitleRight">{{'DISPLACED_ABOMASUM' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.DA | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="htTitleLeft">{{'RETAINED_PLACENTA' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.RP | positiveSign}}</td>
                        <td class="htTitleRight">{{'MILK_FEVER' | translate}}</td>
                        <td class="text-right font-weight-bold">{{product.MF | positiveSign}}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-2">
                <div class="block-header">
                    <span class="title">{{'CALVING_TRAITS' | translate}}</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="ctTitle">{{'SIRE_CALVING_EASE' | translate}}</td>
                        <td class="text-right">{{product.SCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCEObs}}</span>
                            Observ
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="ctTitle">{{'DTR_CALVING_EASE' | translate}}</td>
                        <td class="text-right">{{product.DCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                    <tr>
                        <td class="ctTitle">{{'SIRE_STILLBIRTH' | translate}}</td>
                        <td class="text-right">{{product.SSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBObs}}</span>
                            Observ
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="ctTitle">{{'DAUGHTER_STILLBIRTH' | translate}}</td>
                        <td class="text-right">{{product.DSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</main>
