import { Pipe, PipeTransform } from '@angular/core';
import { ColumnDefinition } from '../models/column-definition.model';

@Pipe({
  name: 'noSticky'
})
export class NoStickyPipe implements PipeTransform {

  transform(value: ColumnDefinition[]): ColumnDefinition[] {
    return value.filter((cd) => !cd.stick);
  }

}
