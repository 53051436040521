import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShoppingCartItem } from '../models/shopping-cart-item.model';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { JetstreamService } from '../services/jetstream.service';
import { OrderDto, CustomerInformation, OrderItem } from '../models/order-dto.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messages } from '../utils/constants';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  cartIsValid = true;
  orderPlaced = false;
  items: ShoppingCartItem[] = [];
  customerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    farmName: new FormControl(''),
    streetAddress: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl( {value: 'United States', disabled: true}, [Validators.required]),
    state: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    comments: new FormControl(''),
    recaptcha: new FormControl(null, Validators.required)
  });

  constructor(public scService: ShoppingCartService, private _jsService: JetstreamService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.items = this.scService.getItems();
    this.cartIsValid = this.scService.cartIsValid();
  }

  placeOrder() {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid && this.cartIsValid) {
      const order = new OrderDto();
      order.shippingTax = this.scService.getShippingTax();
      order.discount = this.scService.getDiscount();
      order.discountPercent = this.scService.getDiscountPercent();
      order.grandTotal = this.scService.getGrandTotal();
      order.customer = new CustomerInformation(this.customerForm.controls['name'].value,
        this.customerForm.controls['farmName'].value,
        this.customerForm.controls['streetAddress'].value,
        this.customerForm.controls['city'].value,
        this.customerForm.controls['state'].value,
        this.customerForm.controls['country'].value,
        this.customerForm.controls['zipCode'].value,
        this.customerForm.controls['email'].value,
        this.customerForm.controls['phone'].value,
        this.customerForm.controls['comments'].value);
      order.items = this.items.map(i => new OrderItem(i.fullName, i.semenCode, i.intRegNum, i.quantity, i.quantity * i.price));      

      this._jsService.placeOrder(order).subscribe(() => {
        // this.openSnackBar(messages.orderSuccess, null, 'success-dialog');
        this.orderPlaced = true;
        this.scService.resetCart();
        this.items = this.scService.getItems();
      }, error => {
        this.openSnackBar(messages.error, messages.tryAgain, 'error-dialog')
      })
    }
  }

  // resolved(captchaResponse: string) {
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }

  openSnackBar(message: string, action: string, statusClass: string) {
    const ref = this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: statusClass,
      verticalPosition: 'top'
    });
    ref.onAction().subscribe(() => {
      this.placeOrder();
    })
  }
}
