<main class="container py-3" *ngIf="product">
    <div class="row my-4 header">
        <div class="col-12 col-md-6 mb-3 mb-md-0 d-flex align-items-start justify-content-center">
            <img class="img-fluid mat-elevation-z4"
                src="{{bsUrl}}{{product.BullImage}}">
        </div>
        <div class="col-12 col-md-6">
            <h1 class="mb-0">{{product.ShortName}}</h1>
            <h3 class="mb-0">{{product.SemenCode}}</h3>
            <h3 class="mb-0">{{product.FullName}}</h3>
            <h3 class="mb-0 font-weight-normal">{{product.Pedigree}}</h3>
            <h3>{{product.IntRegNum}} | DOB {{product.BirthDate | date: 'MM/dd/yyyy'}}</h3>
            <p>
                <span class="font-weight-bold">Kappa-Casein </span>{{product.KappaCasein}} |
                <span class="font-weight-bold">Beta-Casein </span>{{product.BetaCasein}}
            </p>
            <p><span class="font-weight-bold">Haplotypes </span>{{product.Haplotypes}}</p>
            <p><span class="font-weight-bold">Genetic Codes </span>{{product.GeneticCodes}}</p>
            <p>
                <span class="font-weight-bold">aAa </span>{{product.aAa}} |
                <span class="font-weight-bold">DMS </span>{{product.DMS}} |
                <span class="font-weight-bold">EFI </span>{{product.EFI}}%
            </p>

            <p><span class="font-weight-bold">Current Proof </span>{{product.CurrentProof}}</p>
            <p><span class="font-weight-bold">Data </span>Powered by CDCB | HA-USA</p>

            <div class="example-config mt-2">
              <button class="btn-dark" mat-raised-button kendoButton (click)="exportPdf()">
                Print PDF
              </button>
            </div>

            <div class="my-2 add-cart" *ngIf="product.Price ; else outOfStock">
                <p><span class="price">{{product.Price | currency}}</span> Availability: In stock</p>
                <label for="txtQty" class="d-block">Quantity</label>
                <input class="text-right" [ngClass]="{'error shake-me': !valid}" id="txtQty" name="txtQty" type="number"
                    step="10" min="10" [(ngModel)]="quantity">
                <small class="d-block" [ngClass]="{'error shake-me': !valid}">*This product is available for purchase in
                    increments of 10</small>
                <div class="my-3">
                    <button type="button" mat-raised-button class="add-button green-button py-1" (click)="addToCart()">
                        <mat-icon class="mr-2">add_shopping_cart</mat-icon>
                        <span>Add to cart</span>
                    </button>
                </div>
            </div>
            <ng-template #outOfStock>
                <div class="my-3 out-of-stock">
                    <p>Availability: <span class="error font-weight-bold">Out of stock</span></p>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-12 col-md-6">
            <div class="block">
                <div class="block-header">
                    <span class="title">Conformation </span>
                    <span>Based on
                        <span class="font-weight-bold">{{conformationDaughters | zeroToDash}}</span>
                        daughters in
                        <span class="font-weight-bold">{{conformationHerds | zeroToDash}}</span>
                        herds ({{product.ConfRel}}% Rel)
                    </span>
                </div>
                <table class="w-100 chart">
                    <tr>
                        <td>PTAT</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.PTAT >= 0, 'negative': product.PTAT < 0}"
                                    [style.width]="getBarWidth(product.PTAT)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.PTAT}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>UDC</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.UDC >= 0, 'negative': product.UDC < 0}"
                                    [style.width]="getBarWidth(product.UDC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.UDC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>FLC</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.FLC >= 0, 'negative': product.FLC < 0}"
                                    [style.width]="getBarWidth(product.FLC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.FLC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let c of product.Conformations | sortBy:'asc':'Order'">
                        <td>{{c.TraitTranslated}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': c.Value >= 0, 'negative': c.Value < 0}"
                                    [style.width]="getBarWidth(c.Value)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{c.Value | number:'1.2-2' | positiveSign }}</td>
                        <td>{{c.Value >= 0 ? c.Pos : c.Neg}}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-3" *ngIf="product.PedigreeList && product.PedigreeList.length">
                <div class="block-header">
                    <span class="title">Pedigree</span>
                </div>
                <table class="w-100">
                    <tr *ngFor="let p of product.PedigreeList | sortBy:'asc':'Order'">
                        <td>{{p.Type}}</td>
                        <td>{{p.Name}}</td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="col-12 col-md-6">
            <div class="tpi-nm-container">
                <div>
                    <span>TPI</span>
                    <span>{{product.TPI}}</span>
                </div>
                <div>
                    <span>NM$</span>
                    <span>{{product.NM}}</span>
                </div>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Production</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">Milk</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Milk | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.MilkRel}}%</span>
                            Rel
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Protein</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Prot | positiveSign}}
                            </span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Fat</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.Fat | positiveSign}}</span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header gray">
                    <span>Based on
                        <span class="font-weight-bold">{{productionDaughters | zeroToDash}}</span>
                        daughters in
                        <span class="font-weight-bold">{{productionHerds | zeroToDash}}</span>
                        herds
                        <span class="font-weight-bold">({{product.USDaughters}}% US Daughters)</span>
                    </span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">Milk Production - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.MilkUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right"><span class="font-weight-bold">{{product.MilkUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Fat Yield - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.FatUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Protein Yield - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.ProtUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Health traits</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-35">Productive Life</td>
                        <td class="text-right font-weight-bold">{{product.PL | positiveSign}}</td>
                        <td class="pl-3">Daughter Pregnancy Rate</td>
                        <td class="text-right font-weight-bold">{{product.DPR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Livability</td>
                        <td class="text-right font-weight-bold">{{product.LIV | positiveSign}}</td>
                        <td class="pl-3">Cow Conception Rate</td>
                        <td class="text-right font-weight-bold">{{product.CCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Somatic Cell Score</td>
                        <td class="text-right font-weight-bold">{{product.SCS | positiveSign}}</td>
                        <td class="pl-3">Heifer Conception Rate</td>
                        <td class="text-right font-weight-bold">{{product.HCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Mastitis</td>
                        <td class="text-right font-weight-bold">{{product.Mast | positiveSign}}</td>
                        <td class="pl-3">Metritis</td>
                        <td class="text-right font-weight-bold">{{product.MET | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Ketosis</td>
                        <td class="text-right font-weight-bold">{{product.KET | positiveSign}}</td>
                        <td class="pl-3">Displaced Abomasum</td>
                        <td class="text-right font-weight-bold">{{product.DA | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Retained Placenta</td>
                        <td class="text-right font-weight-bold">{{product.RP | positiveSign}}</td>
                        <td class="pl-3">Milk Fever</td>
                        <td class="text-right font-weight-bold">{{product.MF | positiveSign}}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Calving traits</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td>Sire Calving Ease</td>
                        <td class="text-right">{{product.SCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCEObs}}</span>
                            Observ
                        </td>
                    </tr>
                    <tr>
                        <td>Dtr Calving Ease</td>
                        <td class="text-right">{{product.DCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                    <tr>
                        <td>Sire stillbirth</td>
                        <td class="text-right">{{product.SSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBObs}}</span>
                            Observ
                        </td>
                    </tr>
                    <tr>
                        <td>Daughter stillbirth</td>
                        <td class="text-right">{{product.DSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <app-shopping-cart-button></app-shopping-cart-button>
</main>

<!-- Print PDF -->
<div class="PDFComp">
<kendo-pdf-export #pdf margin="0cm" paperSize="A4">
    <app-catalog-dairy1p [product]="product"></app-catalog-dairy1p>
  <!-- <main class="py-3 containerPDF" *ngIf="product">
    <div class="my-4 row header">
      <div class="col-6 img-pdf">
        <img class="mat-elevation-z4" width="700"
            src="{{bsUrl}}{{product.BullImage}}">
      </div>
      <div class="col-6">
        <p><span class="mb-0 bullName">{{product.ShortName}}</span>
        <h3 class="mb-0">{{product.SemenCode}}</h3>
        <h3 class="mb-0">{{product.FullName}}</h3>
        <h3 class="mb-0 font-weight-normal">{{product.Pedigree}}</h3>
        <h3>{{product.IntRegNum}} | DOB {{product.BirthDate | date: 'MM/dd/yyyy'}}</h3>
        <p>
            <span class="font-weight-bold mr-1">Kappa-Casein </span>{{product.KappaCasein}} |
            <span class="font-weight-bold ml-1 mr-1">Beta-Casein </span>{{product.BetaCasein}}
        </p>
        <p>
          <span class="font-weight-bold">Haplotypes </span>{{product.Haplotypes}}</p>
        <p>
          <span class="font-weight-bold mr-1">Genetic Codes </span>{{product.GeneticCodes}}</p>
        <p>
            <span class="font-weight-bold">aAa </span>{{product.aAa}} |
            <span class="font-weight-bold">DMS </span>{{product.DMS}} |
            <span class="font-weight-bold">EFI </span>{{product.EFI}}%
        </p>

        <p><span class="font-weight-bold">Current Proof </span>{{product.CurrentProof}}</p>
        <p>CDCB & HA-USA Genetic Evaluations {{product.CurrentProof | evaluationDate}}</p>
      </div>

    </div>
    <div class="my-4 row">
        <div class="col-6">
            <div class="block">
                <div class="block-header">
                    <span class="title">Conformation </span>
                    <span>Based on
                        <span class="font-weight-bold">{{conformationDaughters | zeroToDash}}</span>
                        daughters in
                        <span class="font-weight-bold">{{conformationHerds | zeroToDash}}</span>
                        herds ({{product.ConfRel}}% Rel)
                    </span>
                </div>
                <table class="w-100 chart">
                    <tr>
                        <td>PTAT</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar"
                                    [ngClass]="{'positive': product.PTAT >= 0, 'negative': product.PTAT < 0}"
                                    [style.width]="getBarWidth(product.PTAT)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.PTAT}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>UDC</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.UDC >= 0, 'negative': product.UDC < 0}"
                                    [style.width]="getBarWidth(product.UDC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.UDC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>FLC</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': product.FLC >= 0, 'negative': product.FLC < 0}"
                                    [style.width]="getBarWidth(product.FLC)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{product.FLC}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="chart-index">&nbsp;
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let c of product.Conformations | sortBy:'asc':'Order'">
                        <td>{{c.TraitTranslated}}</td>
                        <td class="chart-container">
                            <div class="bar-container">
                                <div class="bar" [ngClass]="{'positive': c.Value >= 0, 'negative': c.Value < 0}"
                                    [style.width]="getBarWidth(c.Value)">
                                </div>
                            </div>
                        </td>
                        <td class="font-weight-bold">{{c.Value | number:'1.2-2' | positiveSign }}</td>
                        <td>{{c.Value >= 0 ? c.Pos : c.Neg}}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-3" *ngIf="product.PedigreeList && product.PedigreeList.length">
                <div class="block-header">
                    <span class="title">Pedigree</span>
                </div>
                <table class="w-100">
                    <tr *ngFor="let p of product.PedigreeList | sortBy:'asc':'Order'">
                        <td>{{p.Type}}</td>
                        <td>{{p.Name}}</td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="col-6">
            <div class="tpi-nm-container">
                <div>
                    <span>TPI</span>
                    <span>{{product.TPI}}</span>
                </div>
                <div>
                    <span>NM$</span>
                    <span>{{product.NM}}</span>
                </div>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Production</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">Milk</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Milk | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.MilkRel}}%</span>
                            Rel
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Protein</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.Prot | positiveSign}}
                            </span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Fat</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.Fat | positiveSign}}</span>
                            Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatPerc | positiveSign}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header gray">
                    <span>Based on
                        <span class="font-weight-bold">{{productionDaughters | zeroToDash}}</span>
                        daughters in
                        <span class="font-weight-bold">{{productionHerds | zeroToDash}}</span>
                        herds
                        <span class="font-weight-bold">({{product.USDaughters}}% US Daughters)</span>
                    </span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-50">Milk Production - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.MilkUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right"><span class="font-weight-bold">{{product.MilkUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Fat Yield - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.FatUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.FatUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-50">Protein Yield - US daughters</td>
                        <td class="text-right">
                            <span class="font-weight-bold">
                                {{product.ProtUSDaughters | positiveSign}}
                            </span> Lbs
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.ProtUSDaughtersPerc}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Health traits</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td class="w-35">Productive Life</td>
                        <td class="text-right font-weight-bold">{{product.PL | positiveSign}}</td>
                        <td class="pl-3">Daughter Pregnancy Rate</td>
                        <td class="text-right font-weight-bold">{{product.DPR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Livability</td>
                        <td class="text-right font-weight-bold">{{product.LIV | positiveSign}}</td>
                        <td class="pl-3">Cow Conception Rate</td>
                        <td class="text-right font-weight-bold">{{product.CCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Somatic Cell Score</td>
                        <td class="text-right font-weight-bold">{{product.SCS | positiveSign}}</td>
                        <td class="pl-3">Heifer Conception Rate</td>
                        <td class="text-right font-weight-bold">{{product.HCR | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Mastitis</td>
                        <td class="text-right font-weight-bold">{{product.Mast | positiveSign}}</td>
                        <td class="pl-3">Metritis</td>
                        <td class="text-right font-weight-bold">{{product.MET | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Ketosis</td>
                        <td class="text-right font-weight-bold">{{product.KET | positiveSign}}</td>
                        <td class="pl-3">Displaced Abomasum</td>
                        <td class="text-right font-weight-bold">{{product.DA | positiveSign}}</td>
                    </tr>
                    <tr>
                        <td class="w-35">Retained Placenta</td>
                        <td class="text-right font-weight-bold">{{product.RP | positiveSign}}</td>
                        <td class="pl-3">Milk Fever</td>
                        <td class="text-right font-weight-bold">{{product.MF | positiveSign}}</td>
                    </tr>
                </table>
            </div>
            <div class="block my-3">
                <div class="block-header">
                    <span class="title">Calving traits</span>
                </div>
                <table class="w-100">
                    <tr>
                        <td>Sire Calving Ease</td>
                        <td class="text-right">{{product.SCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SCEObs}}</span>
                            Observ
                        </td>
                    </tr>
                    <tr>
                        <td>Dtr Calving Ease</td>
                        <td class="text-right">{{product.DCE}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCERel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DCEDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                    <tr>
                        <td>Sire stillbirth</td>
                        <td class="text-right">{{product.SSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.SSBObs}}</span>
                            Observ
                        </td>
                    </tr>
                    <tr>
                        <td>Daughter stillbirth</td>
                        <td class="text-right">{{product.DSB}}</td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBRel}}%</span>
                            Rel
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBObs}}</span>
                            Observ
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bold">{{product.DSBDtr}}</span>
                            dtr.
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
  </main> -->
</kendo-pdf-export>
</div>


