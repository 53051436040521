<div class="container py-3">
    <div class="row">
        <div class="col-9">
            <h1>Purchase our sires</h1>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
           <app-shopping-cart-button></app-shopping-cart-button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col d-none d-md-flex align-items-center">
            <h3>General Information</h3>
        </div>
        <div class="col d-flex align-items-center">
            <h3>Your traits</h3>
            <button mat-flat-button color="primary" class="mx-2 text-white font-weight-normal"
                (click)="openTraitsDialog()">
                <span>Edit traits</span>
                <mat-icon>list</mat-icon>
            </button>
            <button mat-flat-button color="primary" class="mx-2 text-white font-weight-normal" (click)="openCreateCatalog()">
                <span>Create Catalog</span>
            </button>
            
        </div>
        <div class="col-12 col-md-4 text-right">
            <mat-form-field class="w-100">
                <mat-label>NAAB Code, Name</mat-label>
                <input matInput [(ngModel)]="filter" name="txtFilter" class="text-uppercase"
                    (ngModelChange)="this.filterUpdate.next($event)">
                <mat-icon matSuffix *ngIf="!filter">search</mat-icon>
                <button class="clear-button" mat-button matSuffix mat-icon-button (click)="clearFilter()"
                    *ngIf="filter">
                    <mat-icon class="text-muted">close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>    
    <div class="row" *ngIf="!loaderService.loading">
        <div class="col">

            <button type="button" mat-fab class="scroll-button left hide" (click)="scroll(-150)">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-fab class="scroll-button right hide" (click)="scroll(150)">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <div #tableContainer class="table-container">
                <table mat-table matSort matSortDisableClear matSortActive="TPI" matSortDirection="desc"
                    [dataSource]="dataSource" class="w-100">

                    <!-- CHECKBOX -->
                    <ng-container matColumnDef="Select" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                          </th>
                        <td mat-cell *matCellDef="let bull">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(bull) : null"
                                        [checked]="selection.isSelected(bull)">
                        </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- NAAB CODE -->
                    <ng-container matColumnDef="SemenCode" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="semen-code">NAAB Code</th>
                        <td mat-cell *matCellDef="let bull">
                            <a [routerLink]="bull.permalink">
                                {{bull.SemenCode}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Name -->
                    <ng-container matColumnDef="ShortName" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="name">Short Name</th>
                        <td mat-cell *matCellDef="let bull">
                            <a [routerLink]="bull.permalink">
                                {{bull.ShortName}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Price -->
                    <ng-container matColumnDef="Price" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="price">Price</th>
                        <td mat-cell *matCellDef="let bull" class="price" [class.text-center]="!bull.price">
                            {{bull.Price  | customCurrency }}
                        </td>
                    </ng-container>

                    <!-- GTPI -->
                    <ng-container matColumnDef="TPI">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">GTPI</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.TPI}} </td>
                    </ng-container>

                    <!-- NM$ -->
                    <ng-container matColumnDef="NM">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">NM$</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.NM}} </td>
                    </ng-container>

                    <!-- Milk -->
                    <ng-container matColumnDef="Milk">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Milk</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.Milk}} </td>
                    </ng-container>

                    <!-- Fat -->
                    <ng-container matColumnDef="Fat">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Fat</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.Fat}} </td>
                    </ng-container>

                    <!-- Fat % -->
                    <ng-container matColumnDef="FatPerc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Fat %</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.FatPerc}} </td>
                    </ng-container>

                    <!-- Protein -->
                    <ng-container matColumnDef="Prot">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Protein</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.Prot}} </td>
                    </ng-container>

                    <!-- Prot % -->
                    <ng-container matColumnDef="ProtPerc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Pro %</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.ProtPerc}} </td>
                    </ng-container>

                    <!-- Type -->
                    <ng-container matColumnDef="PTAT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Type</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.PTAT}} </td>
                    </ng-container>

                    <!-- UDC -->
                    <ng-container matColumnDef="UDC">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">UDC</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.UDC}} </td>
                    </ng-container>

                    <!-- FLC -->
                    <ng-container matColumnDef="FLC">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">FLC</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.FLC}} </td>
                    </ng-container>

                    <!-- PL -->
                    <ng-container matColumnDef="PL">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">PL</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.PL}} </td>
                    </ng-container>

                    <!-- DPR -->
                    <ng-container matColumnDef="DPR">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">DPR</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.DPR}} </td>
                    </ng-container>

                    <!-- SCE -->
                    <ng-container matColumnDef="SCE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">SCE</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.SCE}} </td>
                    </ng-container>

                    <!-- BCN -->
                    <ng-container matColumnDef="BetaCasein">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">BCN</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.BetaCasein}} </td>
                    </ng-container>

                    <!-- CM$ -->
                    <ng-container matColumnDef="CM">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">CM$</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.CM}} </td>
                    </ng-container>

                    <!-- CFP -->
                    <ng-container matColumnDef="CFP">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">CFP</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.CFP}} </td>
                    </ng-container>

                    <!-- Prel -->
                    <ng-container matColumnDef="Prel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">Prel</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.Prel}} </td>
                    </ng-container>

                    <!-- SCS -->
                    <ng-container matColumnDef="SCS">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">SCS</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.SCS}} </td>
                    </ng-container>

                    <!-- DCE -->
                    <ng-container matColumnDef="DCE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">DCE</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.DCE}} </td>
                    </ng-container>

                    <!-- SSB -->
                    <ng-container matColumnDef="SSB">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">SSB</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.SSB}} </td>
                    </ng-container>

                    <!-- DSB -->
                    <ng-container matColumnDef="DSB">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">DSB</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.DSB}} </td>
                    </ng-container>

                    <!-- LIV -->
                    <ng-container matColumnDef="LIV">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">LIV</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.LIV}} </td>
                    </ng-container>

                    <!-- TRel -->
                    <ng-container matColumnDef="TRel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">TRel</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.TRel}} </td>
                    </ng-container>

                    <!-- aAa -->
                    <ng-container matColumnDef="aAa">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">aAa</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.aAa}} </td>
                    </ng-container>

                    <!-- DMS -->
                    <ng-container matColumnDef="DMS">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">DMS</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.DMS}} </td>
                    </ng-container>

                    <!-- Pedigree -->
                    <ng-container matColumnDef="Pedigree">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait pedigree">Pedigree</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.Pedigree}} </td>
                    </ng-container>

                    <!-- CCR -->
                    <ng-container matColumnDef="CCR">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">CCR</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.CCR}} </td>
                    </ng-container>

                    <!-- HCR -->
                    <ng-container matColumnDef="HCR">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="trait">HCR</th>
                        <td mat-cell *matCellDef="let bull" class="trait"> {{bull.HCR}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                </table>
            </div>
            <mat-paginator [pageSize]="50" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
        </div>
    </div>
</div>