import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BullSearchService } from '../services/bull-search.service';
import { LoaderService } from '../services/loader.service';
import { pageTitles } from '../utils/constants';
import { TopGtpiBull } from '../models/top-gtpi-bull.model';
import { FeaturedBull } from '../models/featured-bull.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  featuredBulls: FeaturedBull[] = [];
  topFive: TopGtpiBull[] = [];

  constructor(private _bsService: BullSearchService, public loaderService: LoaderService, private _titleService: Title) { }

  ngOnInit(): void {
    this._titleService.setTitle(pageTitles.home);    
    this.loadFeaturedBulls();
    this.loadTopGtpi(5);
  }
 

  loadFeaturedBulls() {
    this._bsService.getFeaturedBulls().subscribe((response) => {
      this.featuredBulls = response;
    }, error => {
      console.log(error);
    });
  }

  loadTopGtpi(qty: number) {
    this._bsService.getTopGtpi(qty).subscribe((response) => {
      this.topFive = response;
    }, error => {
      console.log(error);
    });
  }

}