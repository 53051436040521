import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroToDash'
})
export class ZeroToDashPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return '-';
    }
    return value;
  }

}
