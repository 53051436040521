import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(value: number): string {    
    if (!value) {
      return '----';
    } 
    return this._currencyPipe.transform(value);
  }

}
