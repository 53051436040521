import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'
import { BullListItem } from '../models/bull-list-item.model'
import { TopGtpiBull } from '../models/top-gtpi-bull.model';
import { Router } from '@angular/router';
import { Product } from '../models/product.model';
import { FeaturedBull } from '../models/featured-bull.model';

@Injectable({
  providedIn: 'root'
})
export class BullSearchService {

  private bsApiUrl = environment.bsApiUrl;

  constructor(private httpClient: HttpClient, private router: Router) { }

  getBulls(): Observable<BullListItem[]> {
    return this.httpClient.get<BullListItem[]>(`${this.bsApiUrl}/bulls/USA`).pipe(retry(0), catchError(this.handleError));
  }

  getBull(permalink: string, catalog?: boolean): Observable<Product> {
    let headers = new HttpHeaders();
    if (catalog)
      headers = headers.append('catalog', 'create');

    return this.httpClient.get<Product>(`${this.bsApiUrl}/bull/${permalink}/USA`, { headers: headers }).pipe(retry(0), catchError(this.handleError));
  }

  getFeaturedBulls(): Observable<FeaturedBull[]> {
    return this.httpClient.get<FeaturedBull[]>(`${this.bsApiUrl}/Featured/USA`).pipe(retry(0), catchError(this.handleError));
    // return forkJoin([
    //   this.httpClient.get<Product>(`${this.bsApiUrl}/bull/${currentFeaturedBulls[0]}/USA`).pipe(retry(0), catchError(this.handleError)), 
    //   this.httpClient.get<Product>(`${this.bsApiUrl}/bull/${currentFeaturedBulls[1]}/USA`).pipe(retry(1), catchError(this.handleError)), 
    //   this.httpClient.get<Product>(`${this.bsApiUrl}/bull/${currentFeaturedBulls[2]}/USA`).pipe(retry(2), catchError(this.handleError))
    // ]);
  }

  getTopGtpi(qty: number): Observable<TopGtpiBull[]> {
    return this.httpClient.get<TopGtpiBull[]>(`${this.bsApiUrl}/BullTopTPI/USA/${qty}`).pipe(retry(0), catchError(this.handleError));
  }

  getGeneticSummary(summary: any): Observable<any> {
    return this.httpClient.post<any>(`${this.bsApiUrl}/genetic-summary`, summary).pipe(retry(0), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}`)
      console.error(error);
      if (error.status === 404) {
        this.router.navigateByUrl('/404', { skipLocationChange: true });
      }
    }
    // return an observable with a user-facing error message
    return throwError('Error performing operation');
  }
}
