import { ShoppingCartItem } from './shopping-cart-item.model';
import { threeDaysShippingTax } from '../utils/constants';

export class ShoppingCart {
    items: ShoppingCartItem[];
    shippingTax: number;
    coupon: string;   

    constructor() {
        this.items = [];
        this.shippingTax = threeDaysShippingTax;        
        this.coupon = '';
    }
}