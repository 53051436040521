<mat-card class="example-card mat-elevation-z8" *ngIf="product">
    <img mat-card-image src="{{bsUrl}}{{product.BullImage}}"
        alt="{{product.FullName}}">
    <mat-card-content class="d-flex flex-column align-items-center">
        <h1>{{product.FullName}}</h1>
        <h2 class="d-flex">
            <span>$ </span>
            <span>{{product.Price}}</span>
        </h2>
        <a mat-button class="green my-3" routerLink="/purchase-our-sires/{{product.permalink}}">Add To Cart</a>
    </mat-card-content>
</mat-card>